import React, { useState, useCallback, useEffect, useRef } from "react";
import ConfirmModal from "../modals/ConfirmModal";
import ProductDetailModal from "../modals/ProductDetailModal";
import axios from "../../config/service";
import ReactPaginate from "react-paginate";
import LoadingComponent from "../other/loading";
import SearchComponent from "../input/search";
import { setTitleHeader } from "../../actions/menus";
import { connect } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import "moment/locale/th";
import { useAlert } from "react-alert";
import DatePicker, { registerLocale } from "react-datepicker";
import { th } from "date-fns/esm/locale";
import customHeader from "../calendar/CustomHeaderCalendar";
import currency from "currency.js";

import "react-datepicker/dist/react-datepicker.css";
moment().locale("th");
registerLocale("th", th);

const schemaChooseTotal = Yup.object().shape({
  choose_total: Yup.number("กรุณาเฉพาะตัวเลข")
    .positive("กรุณากรอกเลขจำนวนเต็ม")
    .typeError("กรุณาเฉพาะตัวเลข")
    .integer("กรุณาเฉพาะตัวเลข")
    .required("กรุณากรอกจำนวน"),
});

const schemaCost = Yup.object().shape({
  cost: Yup.string()
    .trim()
    .matches(/^\d+(\.\d{1,2})?$/, "กรุณากรอกเฉพาะตัวเลขและทศนิยมไม่เกิน 2 หลัก")
    .required("กรุณากรอกราคา"),
});

const Index = ({ setTitleHeader, owner }) => {
  const alert = useAlert();
  const [isLoading, setLoading] = useState(true);
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [products, setProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [pageNow, setPageNow] = useState(0);
  const [selectProducts, setSelectProduct] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [productDetail, setProductDetail] = useState({
    name: "",
    description: "",
    buyer: "",
    barCode: "",
    partSpec: "",
    stockPosition: "",
    maximumStock: "",
    warningStock: "",
    packTotal: "",
    pTotal: "",
    vat: "",
  });
  const [dealDate, setDealDate] = useState(new Date());
  const [canSubmit, setCanSubmit] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [historyTotal, setHistoryTotal] = useState(0);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [importDate, setImportDate] = useState(new Date());
  const [boughbackTotal, setBoughtBackTotal] = useState(0);
  const [boughBackHistory, setBoughBackHistory] = useState([]);
  const [historyProducts, setHistoryProducts] = useState([]);
  const isMounted = useRef(true);

  const fetchBoughback = useCallback(async (page = 1, date = null) => {
    setLoading(true);
    try {
      const boughbackAPI = await axios.get(
        `products/refunds${
          date ? `?page=${page}&date=${date}` : `?page=${page}`
        }`
      );
      const {
        data: {
          refunds: { last_page, data: refunds },
        },
      } = boughbackAPI;
      setBoughtBackTotal(last_page);
      setBoughBackHistory(refunds);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert.error("มีข้อผิดพลาดเกิดขึ้น.");
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchProducts = async (page = 1, search = "") => {
    try {
      const productsAPI = await axios.get(
        `products?page=${page}&search=${search}&is_selled=1`
      );
      const {
        data: {
          products: { data, last_page },
        },
      } = productsAPI;
      setProductTotal(last_page);
      setProducts(data);
      setLoading(false);
      setPageNow(page);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchBoughback();
    setTitleHeader({
      title: "ซื้อของคืนเข้าระบบ",
      href: "/boughtback",
    });
    return () => {
      isMounted.current = false;
    };
  }, [fetchBoughback, setTitleHeader]);

  const selectProduct = useCallback(
    (event, product) => {
      const isChecked = event.target.checked;
      const productId = product.id;
      let getSelectProducts = selectProducts;
      if (isChecked) {
        if (
          !getSelectProducts.filter((product) => product.id === productId)
            .length
        ) {
          const setProduct = {
            ...product,
            choose_total: 1,
            cost: 0,
            choose_totalError: false,
            choose_totalErrorMessage: "",
            costError: false,
            costErrorMessage: "",
          };
          getSelectProducts = [...getSelectProducts, setProduct];
          setSelectProduct(getSelectProducts);
        } else {
          alert.show("สินค้านี้มีอยู่ในรายการที่เลือกเรียบร้อยแล้ว.");
        }
      } else {
        const filterProduct = getSelectProducts.filter(
          (product) => product.id !== productId
        );
        setSelectProduct(filterProduct);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectProducts]
  );

  const removeSelectItem = useCallback(
    (productId) => {
      const getSelectProducts = selectProducts;
      const filterProduct = getSelectProducts.filter(
        (product) => product.id !== productId
      );
      setSelectProduct(filterProduct);
    },
    [selectProducts]
  );

  const handleTotalChange = (event, idx, key) => {
    const value = event.target.value;
    let getSelectProducts = [...selectProducts];
    const schemaInstance = key === "cost" ? schemaCost : schemaChooseTotal;
    schemaInstance
      .validate({
        [key]: value,
      })
      .then(() => {
        getSelectProducts[idx][key] = value;
        getSelectProducts[idx][`${key}Error`] = false;
        getSelectProducts[idx][`${key}ErrorMessage`] = "";
        setSelectProduct(getSelectProducts);
      })
      .catch((error) => {
        getSelectProducts[idx][key] = value;
        getSelectProducts[idx][`${key}Error`] = true;
        getSelectProducts[idx][`${key}ErrorMessage`] = error.message;
        setSelectProduct(getSelectProducts);
      });
  };

  const searchOnType = useCallback(async (value) => {
    setLoading(true);
    setSearchValue(value);
    fetchProducts(1, value);
  }, []);

  const handlePageClick = useCallback(
    async (data) => {
      setLoading(true);
      fetchProducts(data.selected + 1, searchValue);
    },
    [searchValue]
  );

  const submitForCheckError = useCallback(() => {
    let isError = false;
    for (const product of selectProducts) {
      if (product.choose_totalError || product.costError) {
        isError = true;
        break;
      }
    }
    setCanSubmit(!isError);
  }, [selectProducts]);

  const submit = async () => {
    try {
      setLoadingButton(true);
      setLoading(true);
      const productFormat = selectProducts.map((product) => {
        return {
          product_id: product.id,
          amount: product.choose_total,
          refund_price: product.cost,
        };
      });
      await axios.post("products/refunds", {
        refund_date: moment(dealDate).format("YYYY-MM-DD 00:00:00"),
        products: productFormat,
      });
      setLoading(false);
      setLoadingButton(false);
      alert.success("เพิ่มข้อมูลสำเร็จ.");
      setSelectProduct([]);
      fetchProducts();
      fetchBoughback();
    } catch (error) {
      setLoading(false);
      setLoadingButton(false);
      console.log(error);
    }
  };

  const searchByDate = useCallback(
    (value) => {
      setDateValue(value);
      fetchBoughback(1, moment(value).format("YYYY-MM-DD"));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePageClickWarehouse = useCallback(
    async (data) => {
      fetchBoughback(
        data.selected + 1,
        dateValue ? moment(dateValue).format("YYYY-MM-DD") : null
      );
    },
    [dateValue, fetchBoughback]
  );

  const handlePageClickHistory = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const historyAPI = await axios.get(
          `products/refunds/${moment(importDate).format(
            "YYYY-MM-DD"
          )}/refund_date?page=${data.selected + 1}`
        );
        const {
          data: {
            refunds: { last_page, data: history },
          },
        } = historyAPI;
        setHistoryProducts(history);
        setHistoryTotal(last_page);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
      }
    },
    [alert, importDate]
  );

  return (
    <>
      <LoadingComponent isLoading={isLoading} />
      <div className="row">
        <div className="col-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">เลือกสินค้า</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  {/* text input */}
                  <div className="form-group">
                    <label>ค้นหาสินค้า</label>
                    <SearchComponent
                      onSearch={searchOnType}
                      placeholder="ชื่อสินค้า, รหัสบาร์โค้ด, ตำแหน่ง ..."
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            style={{ width: "200px" }}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </th>
                          <th>ลำดับ</th>
                          <th>ชื่อสินค้า</th>
                          <th>ตำแหน่ง</th>
                          <th className="text-right">จำนวนคงเหลือ</th>
                          <th className="text-center">เลือก</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, idx) => {
                          return (
                            <tr key={`select-product-${product.id}`}>
                              <td className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={() =>
                                    setProductDetail({
                                      name: product.name,
                                      description: product.description,
                                      buyer: product.agent_embed || '-',
                                      barCode: product.barcode
                                        ? product.barcode.code
                                        : "-",
                                      partSpec: product.part_size,
                                      stockPosition: product.treasury
                                        ? product.treasury.place
                                        : "-",
                                      maximumStock: product.maximum_total,
                                      warningStock: product.warning_total,
                                      packTotal: product.per_pack_total,
                                      pTotal: product.p,
                                      vat: +product.has_vat,
                                      images: product.images,
                                    })
                                  }
                                >
                                  <i className="fa fa-eye mr-1"></i>
                                  ดูรายละเอียด
                                </button>
                              </td>
                              <td>{(pageNow - 1) * 10 + (idx + 1)}</td>
                              <td>{product.name}</td>
                              <td>
                                {product.treasury
                                  ? product.treasury.place
                                  : "-"}
                              </td>
                              <td className="text-right">
                                {product.current_stock}
                              </td>
                              <td className="text-center">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      selectProducts.filter(
                                        (selectProduct) =>
                                          selectProduct.id === product.id
                                      ).length
                                    }
                                    onChange={(e) => selectProduct(e, product)}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 d-flex justify-content-end">
                  <nav aria-label="Page navigation example">
                    <ReactPaginate
                      previousLabel={"ก่อนหน้า"}
                      nextLabel={"ต่อไป"}
                      pageCount={productTotal}
                      pageRangeDisplayed={5}
                      previousClassName={"page-item"}
                      marginPagesDisplayed={1}
                      nextClassName={"page-item"}
                      pageClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      pageLinkClassName={"page-link"}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </nav>
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <label for="exampleInputEmail1">วันที่ทำรายการ</label>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <DatePicker
                  placeholderText={moment(dealDate)
                    .add(543, "years")
                    .format("D MMMM YYYY ")}
                  className="form-control"
                  locale="th"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  onChange={(value) => setDealDate(value)}
                  renderCustomHeader={(props) => customHeader({ ...props })}
                />
              </div>
            </div>
            <div className="col-6 text-md-right">
              <label>ผู้ทำรายงาน</label> : {owner}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card card-warning">
            <div className="card-header">
              <h3 className="card-title">สินค้าที่เลือก</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>รายการสินค้า</th>
                          <th className="text-right">จำนวน(ชิ้น)</th>
                          <th className="text-right">ราคาซื้อคืน</th>
                          <th className="text-right">รวม</th>
                          <th className="text-center">ลบ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectProducts.length > 0 ? (
                          selectProducts.map((product, idx) => {
                            return (
                              <tr key={`selected-product-${product.id}`}>
                                <td>{idx + 1}</td>
                                <td>{product.name}</td>
                                <td>
                                  <input
                                    className="form-control text-right"
                                    type="number"
                                    placeholder="จำนวน"
                                    value={product.choose_total}
                                    onChange={(e) =>
                                      handleTotalChange(e, idx, "choose_total")
                                    }
                                  />
                                  {product.choose_totalError && (
                                    <span className="text-danger">
                                      {product.choose_totalErrorMessage}
                                    </span>
                                  )}
                                </td>
                                <td className="text-right">
                                  <input
                                    className="form-control text-right"
                                    type="number"
                                    placeholder="ราคา"
                                    value={product.cost}
                                    onChange={(e) =>
                                      handleTotalChange(e, idx, "cost")
                                    }
                                  />
                                  {product.costError && (
                                    <span className="text-danger">
                                      {product.costErrorMessage}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {currency(
                                    +product.choose_total * +product.cost,
                                    { separator: ",", symbol: "" }
                                  ).format()}
                                </td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => removeSelectItem(product.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan="6">
                              ยังไม่มีสินค้าที่เลือก
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {selectProducts.length !== 0 && (
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  data-toggle={canSubmit ? "modal" : ""}
                  data-target={canSubmit ? "#confirmModal" : ""}
                  onClick={() => submitForCheckError()}
                  disabled={isLoadingButton}
                >
                  <i className="fas fa-save mr-1"></i>
                  บันทึกรายการ
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">ประวัติซื้อของคืนเข้าระบบ</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  {/* Search form */}
                  <DatePicker
                    name="import_date"
                    placeholderText={moment(dateValue)
                      .add(543, "years")
                      .format("D MMMM YYYY ")}
                    className="form-control w-100"
                    locale="th"
                    dateFormat="dd/MM/yyyy"
                    autoComplete="off"
                    onChange={(value) => searchByDate(value)}
                    renderCustomHeader={(props) => customHeader({ ...props })}
                  />
                </div>
                <div className="col-12">
                  <div className="table-responsive text-nowrap mt-3">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </th>
                          <th className="text-center">วันที่ทำรายการ</th>
                          <th className="text-center">
                            ยอดรวมจำนวนสินค้าซื้อคืนระบบทั้งหมด
                          </th>
                          <th className="text-center">
                            ยอดรวมราคาสินค้าซื้อคืนระบบทั้งหมด
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {boughBackHistory.map((boughback) => {
                          return (
                            <tr key={boughback.id}>
                              <td className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  data-toggle="modal"
                                  data-target="#historyModal"
                                  onClick={() => {
                                    setHistoryProducts(boughback.history.data);
                                    setHistoryTotal(
                                      boughback.history.last_page
                                    );
                                    setImportDate(boughback.refund_date);
                                    setDateSelected(boughback.refund_date);
                                  }}
                                >
                                  <i className="fa fa-eye mr-1"></i>
                                  ดูรายละเอียด
                                </button>
                              </td>
                              <td className="text-center">
                                {moment(boughback.refund_date).add(543, 'years').format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="text-right">
                                {boughback.amount_total}
                              </td>
                              <td className="text-right">
                                {boughback.cost_total}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-3 w-100">
                  <div className="col-12 d-flex justify-content-end">
                    <nav aria-label="Page navigation example">
                      <ReactPaginate
                        previousLabel={"ก่อนหน้า"}
                        nextLabel={"ต่อไป"}
                        pageCount={boughbackTotal}
                        pageRangeDisplayed={5}
                        previousClassName={"page-item"}
                        marginPagesDisplayed={1}
                        nextClassName={"page-item"}
                        pageClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        pageLinkClassName={"page-link"}
                        onPageChange={handlePageClickWarehouse}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </div>
        </div>
      </div>
      <ProductDetailModal {...productDetail} />
      <ConfirmModal
        title={"แจ้งเตือน"}
        description={"คุณต้องการยืนยันซื้อสินค้าคืนหรือไม่"}
        submit={() => submit()}
        cancel={() => {}}
      />
      <div
        class="modal fade"
        id="historyModal"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                ประวัติเพิ่มสินค้าเข้าคลัง วันที่{" "}
                {dateSelected ? moment(dateSelected).add(543, 'years').format("DD/MM/YYYY") : "-"}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>ผู้ทำรายงาน</th>
                          <th>รายการสินค้า</th>
                          <th>จำนวน (ชิ้น)</th>
                          <th className="text-right">ราคาซื้อคืน</th>
                          <th className="text-right">รวม</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyProducts.map((product, idx) => {
                          return (
                            <tr key={product.product_id + idx}>
                              <td>{idx + 1}</td>
                              <td className="text-center">
                                {product.created_by
                                  ? `${product.created_by.name} ${product.created_by.lastname}`
                                  : ``}
                              </td>
                              <td
                                className="td-word-break"
                                style={{ maxWidth: 100 }}
                              >
                                <p>
                                  {product.products
                                    ? product.products.name
                                    : "-"}
                                </p>
                              </td>
                              <td className="text-center">
                                {currency(product.amount, {
                                  separator: ",",
                                  symbol: "",
                                }).format()}
                              </td>
                              <td className="text-right">
                                {currency(product.refund_price, {
                                  separator: ",",
                                  symbol: "",
                                }).format()}
                              </td>
                              <td className="text-right">
                                {currency(
                                  +product.amount * +product.refund_price,
                                  { separator: ",", symbol: "" }
                                ).format()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <ReactPaginate
                    previousLabel={"ก่อนหน้า"}
                    nextLabel={"ต่อไป"}
                    pageCount={historyTotal}
                    pageRangeDisplayed={5}
                    previousClassName={"page-item"}
                    marginPagesDisplayed={1}
                    nextClassName={"page-item"}
                    pageClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    pageLinkClassName={"page-link"}
                    onPageChange={handlePageClickHistory}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  owner: `${state.user.name} ${state.user.lastname}`,
});

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
