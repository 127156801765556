const PAYMENT_CHANNEL = {
  CASH: "CASH",
  CREDIT: "CREDIT",
};

const PAYMENT_CHANNEL_DISPLAY = {
  CASH: "สด",
  CREDIT: "เครดิต",
};

export { PAYMENT_CHANNEL, PAYMENT_CHANNEL_DISPLAY };
