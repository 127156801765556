import React from "react";
import moment from "moment";
import { th } from "date-fns/esm/locale";
import { format, setMonth, getMonth, getYear } from "date-fns";

const customHeader = ({
  increaseMonth,
  nextMonthButtonDisabled,
  decreaseMonth,
  prevMonthButtonDisabled,
  date,
  changeMonth,
  changeYear,
}) => {
  const months = new Array(12).fill(null).map((_, i) => ({
    value: i,
    label: format(setMonth(new Date(), i), "MMMM", {
      locale: th,
    }),
  }));

  const years = new Array(10)
    .fill(null)
    .map((_, i) => +moment().format("YYYY") - 3 + i + 543);

  const handleYearChange = ({ target: { value } }) => changeYear(+value - 543);
  const handleMonthChange = ({ target: { value } }) => changeMonth(value);

  return (
    <div
      style={{
        margin: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
        {"<"}
      </div>

      <select
        onChange={handleMonthChange}
        value={getMonth(new Date(moment(date).add(543, "years")))}
      >
        {months.map(({ value, label }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>

      <select
        onChange={handleYearChange}
        value={getYear(new Date(moment(date).add(543, "years")))}
      >
        {years.map((year) => (
          <option value={year} key={year}>
            {year}
          </option>
        ))}
      </select>

      <div onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
        {">"}
      </div>
    </div>
  );
};

export default customHeader;
