import React, { useState, useCallback, useEffect, useRef } from "react";
import ProductDetailModal from "../modals/ProductDetailModal";
import axios from "../../config/service";
import ReactPaginate from "react-paginate";
import LoadingComponent from "../other/loading";
import SearchComponent from "../input/search";
import { setTitleHeader } from "../../actions/menus";
import { connect } from "react-redux";
import * as Yup from "yup";
import ReactToPrint from "react-to-print";
import moment from "moment";
import "moment/locale/th";
import { useAlert } from "react-alert";
moment().locale("th");

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format("DD MMMM YYYY เวลา HH:mm:ss น."),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: moment().format("DD MMMM YYYY เวลา HH:mm:ss น."),
    });
  }

  render() {
    const { products, owner } = this.props;
    return (
      <div className="pos-container">
        <div className="title">POS-STOCK</div>
        <div className="sub-title mt-1">วันที่ออกรายการ</div>
        <div className="sub-title">{this.state.time}</div>
        <div className="sub-title mt-1" style={{ fontWeight: "bold" }}>
          รายการสินค้าที่เลือก
        </div>
        <table className="table-receipt">
          <tbody>
            <tr className="table-title">
              <td className="name-title-column text-left">สินค้า</td>
              <td className="position-title-column text-left">ตำแหน่ง</td>
              <td className="total-title-column text-right">Q.</td>
            </tr>
            {products.map((product) => {
              return (
                <tr className="order" key={product.id}>
                  <td className="text-left">{product.name}</td>
                  <td className="text-left">
                    {product.treasury ? product.treasury.place : "-"}
                  </td>
                  <td className="text-right">{product.choose_total}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="border-under-table"></div>
        <div className="sub-title my-3">ผู้พิมพ์ : {owner}</div>
        <div className="border-under-table"></div>
      </div>
    );
  }
}

const schema = Yup.object().shape({
  choose_total: Yup.number("กรุณาเฉพาะตัวเลข")
    .typeError("กรุณาเฉพาะตัวเลข")
    .integer("กรุณาเฉพาะตัวเลข")
    .required("กรุณากรอกยอดสูงสุดในการสต็อค"),
});

const Index = ({ setTitleHeader, owner }) => {
  const alert = useAlert();
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [pageNow, setPageNow] = useState(0);
  const [selectProducts, setSelectProduct] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [productDetail, setProductDetail] = useState({
    name: "",
    description: "",
    buyer: "",
    barCode: "",
    partSpec: "",
    stockPosition: "",
    maximumStock: "",
    warningStock: "",
    packTotal: "",
    pTotal: "",
    vat: "",
  });
  const componentRef = useRef();
  const isMounted = useRef(true);

  const fetchProducts = async (page = 1, search = "") => {
    try {
      const productsAPI = await axios.get(
        `products?page=${page}&search=${search}`
      );
      const {
        data: {
          products: { data, last_page },
        },
      } = productsAPI;
      setProductTotal(last_page);
      setProducts(data);
      setLoading(false);
      setPageNow(page);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts();
    setTitleHeader({
      title: "ค้นหาตำแหน่งสินค้า",
      href: "/treasury",
    });
    return () => {
      isMounted.current = false;
    };
  }, [setTitleHeader]);

  const selectProduct = useCallback(
    (event, product) => {
      const isChecked = event.target.checked;
      const productId = product.id;
      let getSelectProducts = selectProducts;
      if (isChecked) {
        if (
          !getSelectProducts.filter((product) => product.id === productId)
            .length
        ) {
          const setProduct = {
            ...product,
            choose_total: 0,
            error: false,
            errorMessage: "",
          };
          getSelectProducts = [...getSelectProducts, setProduct];
          setSelectProduct(getSelectProducts);
        } else {
          alert.show("สินค้านี้มีอยู่ในรายการที่เลือกเรียบร้อยแล้ว.");
        }
      } else {
        const filterProduct = getSelectProducts.filter(
          (product) => product.id !== productId
        );
        setSelectProduct(filterProduct);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectProducts]
  );

  const removeSelectItem = useCallback(
    (productId) => {
      const getSelectProducts = selectProducts;
      const filterProduct = getSelectProducts.filter(
        (product) => product.id !== productId
      );
      setSelectProduct(filterProduct);
    },
    [selectProducts]
  );

  const handleTotalChange = (event, idx) => {
    const value = event.target.value;
    let getSelectProducts = [...selectProducts];
    schema
      .validate({
        choose_total: value,
      })
      .then(() => {
        getSelectProducts[idx].choose_total = value;
        getSelectProducts[idx].error = false;
        getSelectProducts[idx].errorMessage = "";
        setSelectProduct(getSelectProducts);
      })
      .catch((error) => {
        getSelectProducts[idx].choose_total = value;
        getSelectProducts[idx].error = true;
        getSelectProducts[idx].errorMessage = error.message;
        setSelectProduct(getSelectProducts);
      });
  };

  const searchOnType = useCallback(async (value) => {
    setLoading(true);
    setSearchValue(value);
    fetchProducts(1, value);
  }, []);

  const handlePageClick = useCallback(
    async (data) => {
      setLoading(true);
      fetchProducts(data.selected + 1, searchValue);
    },
    [searchValue]
  );

  return (
    <>
      <LoadingComponent isLoading={isLoading} />
      <div className="row">
        <div className="col-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">เลือกสินค้า</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  {/* text input */}
                  <div className="form-group">
                    <label>ค้นหาสินค้า</label>
                    <SearchComponent
                      onSearch={searchOnType}
                      placeholder="ชื่อสินค้า, รหัสบาร์โค้ด, ตำแหน่ง ..."
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th
                            className="text-center"
                            style={{ width: "200px" }}
                          >
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </th>
                          <th>ลำดับ</th>
                          <th>ชื่อสินค้า</th>
                          <th>ตำแหน่ง</th>
                          <th className="text-right">จำนวนคงเหลือ</th>
                          <th className="text-center">เลือก</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, idx) => {
                          return (
                            <tr key={`select-product-${product.id}`}>
                              <td className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={() =>
                                    setProductDetail({
                                      name: product.name,
                                      description: product.description,
                                      buyer: product.agent_embed || "-",
                                      barCode: product.barcode
                                        ? product.barcode.code
                                        : "-",
                                      partSpec: product.part_size,
                                      stockPosition: product.treasury
                                        ? product.treasury.place
                                        : "-",
                                      maximumStock: product.maximum_total,
                                      warningStock: product.warning_total,
                                      packTotal: product.per_pack_total,
                                      pTotal: product.p,
                                      vat: +product.has_vat,
                                      images: product.images,
                                    })
                                  }
                                >
                                  <i className="fa fa-eye mr-1"></i>
                                  ดูรายละเอียด
                                </button>
                              </td>
                              <td>{(pageNow - 1) * 10 + (idx + 1)}</td>
                              <td>{product.name}</td>
                              <td>
                                {product.treasury
                                  ? product.treasury.place
                                  : "-"}
                              </td>
                              <td className="text-right">
                                {product.current_stock}
                              </td>
                              <td className="text-center">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={
                                      selectProducts.filter(
                                        (selectProduct) =>
                                          selectProduct.id === product.id
                                      ).length
                                    }
                                    onChange={(e) => selectProduct(e, product)}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 d-flex justify-content-end">
                  <nav aria-label="Page navigation example">
                    <ReactPaginate
                      previousLabel={"ก่อนหน้า"}
                      nextLabel={"ต่อไป"}
                      pageCount={productTotal}
                      pageRangeDisplayed={5}
                      previousClassName={"page-item"}
                      marginPagesDisplayed={1}
                      nextClassName={"page-item"}
                      pageClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      pageLinkClassName={"page-link"}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </nav>
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card card-warning">
            <div className="card-header">
              <h3 className="card-title">สินค้าที่เลือก</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>ชื่อสินค้า</th>
                          <th>ตำแหน่ง</th>
                          <th className="text-right">จำนวนที่เลือก</th>
                          <th className="text-center">ลบ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectProducts.length > 0 ? (
                          selectProducts.map((product, idx) => {
                            return (
                              <tr key={`selected-product-${product.id}`}>
                                <td>{idx + 1}</td>
                                <td>{product.name}</td>
                                <td>
                                  {product.treasury
                                    ? product.treasury.place
                                    : "-"}
                                </td>
                                <td className="text-right">
                                  <input
                                    className="form-control text-right"
                                    type="number"
                                    placeholder="จำนวน"
                                    defaultValue={product.choose_total}
                                    onChange={(e) => handleTotalChange(e, idx)}
                                  />
                                  {product.error && (
                                    <span className="text-danger">
                                      {product.errorMessage}
                                    </span>
                                  )}
                                </td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() => removeSelectItem(product.id)}
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan="5">
                              ยังไม่มีสินค้าที่เลือก
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {selectProducts.length !== 0 && (
                <ReactToPrint
                  trigger={() => (
                    <button type="submit" className="btn btn-dark btn-block">
                      <i class="fas fa-print"></i> พิมพ์ใบจัดของ
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-none">
        <ComponentToPrint
          ref={componentRef}
          products={selectProducts}
          owner={owner}
        />
      </div>
      <ProductDetailModal {...productDetail} />
    </>
  );
};

const mapStateToProps = (state) => ({
  owner: `${state.user.name} ${state.user.lastname}`,
});

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
