const defaultState = {
  name: "",
  lastname: "",
  telephone: "",
  email: "",
  last_login: "",
  token: "",
  roles: [],
  permissions: []
};

const UserReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "INIT_USER_DATA":
      return Object.assign({}, state, action.value);
    case "RESET_USER_DATA":
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
};

export default UserReducer;
