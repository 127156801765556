import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setTitleHeader } from "../../actions/menus";
import { useFormik } from "formik";
import Yup from "../../utils/yup";
import axios from "../../config/service";
import LoadingComponent from "../other/loading";
import { useAlert } from "react-alert";

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("กรุณากรอกชื่อลูกค้า"),
  tel: Yup.string()
    .trim()
    .required("กรุณากรอกเบอร์โทรศัพท์")
    .matches(/^[0-9]+$/, "กรุณากรอกเฉพาะตัวเลข")
    .min(9, "เบอร์โทรศัพท์ควรมีจำนวน 9 หลักหรือ 10 หลัก")
    .max(10, "เบอร์โทรศัพท์ควรมีจำนวน 9 หลักหรือ 10 หลัก"),
});

const EditForm = ({
  match: {
    params: { id: customerId },
  },
  history,
  setTitleHeader,
}) => {
  const alert = useAlert();
  const [customerTypesItems, setCustomerTypeItems] = useState([]);
  const [customer, setCustomer] = useState({
    name: "",
    tel: "",
    address: "",
    customer_type_id: ""
  });
  const [isLoading, setLoading] = useState(true);
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const customer = await axios.get(`customer/${customerId}`);
        const {
          data: { customer: customerData },
        } = customer;
        setCustomer({
          name: customerData.name,
          tel: customerData.tel,
          address: customerData.address,
          customer_type_id: customerData.customer_type_id
        });
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        console.log(error);
      }
    };
    const fetchCustomerType = async () => {
      try {
        const getCustomerTypes = await axios.get("customer/type");
        setCustomerTypeItems(getCustomerTypes.data.customer_type);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        console.log(error);
      }
    };
    fetchCustomer();
    fetchCustomerType();
    setTitleHeader({
      title: "แก้ไขลูกค้า",
      href: `/customers/edit/${customerId}`,
    });
    return () => {
      isMounted.current = false;
    };
  }, [alert, customerId, setTitleHeader]);

  const onSubmitData = useCallback(
    async (value, setFieldError, setSubmitting) => {
      if (isLoading) {
        return;
      }

      if (isMounted.current) {
        setLoading(true);
      }
      await axios
        .post(`customer/${customerId}`, {
          name: value.name,
          tel: value.tel,
          address: value.address,
          customer_type_id: value.customer_type_id
        })
        .then(() => {
          alert.success("เพิ่มข้อมูลสำเร็จ.");
          history.push("/customers");
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            for (const [key, value] of Object.entries(error.response.data)) {
              if (isMounted.current) {
                setFieldError(key, value);
              }
            }
          }
          if (isMounted.current) {
            setSubmitting(false);
            setLoading(false);
          }
        });
    },
    [alert, customerId, history, isLoading]
  );

  const formik = useFormik({
    initialValues: customer,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      await onSubmitData(values, setFieldError, setSubmitting);
    },
  });

  return (
    <>
      <LoadingComponent isLoading={isLoading} />
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">ฟอร์มแก้ไขข้อมูลลูกค้า</h3>
        </div>
        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">ชื่อลูกค้า</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="กรุณากรอกชื่อลูกค้า"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.errors.name && (
                    <span className="text-danger">{formik.errors.name}</span>
                  )}
                </div>
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">เบอร์โทรศัพท์</label>
                  <input
                    type="tel"
                    name="tel"
                    className="form-control"
                    placeholder="กรุณากรอกเบอร์โทรศัพท์"
                    onChange={formik.handleChange}
                    value={formik.values.tel}
                  />
                  {formik.errors.tel && (
                    <span className="text-danger">{formik.errors.tel}</span>
                  )}
                </div>
                <div className="form-group">
                  <label>ที่อยู่</label>
                  <textarea
                    name="address"
                    className="form-control"
                    rows="3"
                    onChange={formik.handleChange}
                    value={formik.values.address}
                  ></textarea>
                  {formik.errors.address && (
                    <span className="text-danger">{formik.errors.address}</span>
                  )}
                </div>
                <div className="form-group">
                            <label for="exampleFormControlSelect1">
                              ประเภทลูกค้า
                            </label>
                            <select
                              name="customer_type_id"
                              className="form-control"
                              value={formik.values.customer_type_id}
                              onChange={formik.handleChange}
                            >
                              {customerTypesItems.map((value) => {
                                return (
                                  <option key={value.id} value={value.id}>
                                    {value.type}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            {isLoading ? (
              <button type="button" className="btn btn-primary">
                <i className="fas fa-spinner fa-spin"></i>
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                <i className="fas fa-save"></i> บันทึก
              </button>
            )}
            <Link to="/customers">
              <button
                type="button"
                className="btn btn-secondary float-right"
                disabled={isLoading}
              >
                <i className="fas fa-window-close"></i> ยกเลิก
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(EditForm));
