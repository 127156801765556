import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "../../config/service";
import DatePicker, { registerLocale } from "react-datepicker";
import { setTitleHeader } from "../../actions/menus";
import { connect } from "react-redux";
import SearchComponent from "../input/search";
import Carousel from "../carousel/Carousel";
import LoadingComponent from "../other/loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { useAlert } from "react-alert";
import customHeader from "../calendar/CustomHeaderCalendar";

import "react-datepicker/dist/react-datepicker.css";

import { th } from "date-fns/esm/locale";
registerLocale("th", th);

const validationSchema = Yup.object().shape({
  import_date: Yup.string().trim().required("กรุณากรอกราคา"),
  total: Yup.number("กรุณากรอกเฉพาะตัวเลข")
    .typeError("กรุณากรอกเฉพาะตัวเลข")
    .integer("กรุณากรอกเฉพาะตัวเลข")
    .min(1, "กรุณากรอกจำนวนอย่างน้อยหนึ่งจำนวน")
    .required("กรุณากรอกราคา"),
  cost: Yup.string()
    .trim()
    .matches(/^\d+(\.\d{1,2})?$/, "กรุณากรอกเฉพาะตัวเลขและทศนิยมไม่เกิน 2 หลัก")
    .required("กรุณากรอกราคา"),
  is_pack: Yup.string().trim().required("กรุณาเลือกประเภทนำเข้า"),
});

const Warehouse = ({ setTitleHeader, owner }) => {
  const alert = useAlert();
  const [product, setProduct] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnLoadingAddList, setBtnLoadingAddList] = useState(false);
  const [productLists, setProductLists] = useState([]);
  const [summary, setSummary] = useState({
    total: 0,
    price: 0,
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    setTitleHeader({
      title: "จัดการสินค้าเข้าคลัง",
      href: "/warehouse",
    });
    return () => {
      isMounted.current = false;
    };
  }, [setTitleHeader]);

  const formik = useFormik({
    initialValues: {
      import_date: new Date(),
      total: "",
      cost: "",
      is_pack: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setFieldValue, setFieldError, setSubmitting, resetForm }) => {
      setBtnLoadingAddList(true);
      try {
        await axios.post(`products/validate/maximum_total/${product.id}`, {
          total: values.total,
          is_pack: values.is_pack
        });
        setBtnLoadingAddList(false);
        const isDuplicate = productLists.filter(
          (productItem) => productItem.product_id === product.id
        );
        if (isDuplicate.length) {
          alert.show("คุณเลือกสินค้านี้ลงในรายการแล้ว กรุณาลบสินค้าออกก่อน.");
          return false;
        }
        const productListsData = [
          ...productLists,
          {
            product_id: product.id,
            name: product.name,
            total: values.total,
            cost: values.cost,
            is_pack: values.is_pack,
            import_date: moment(values.import_date).format("YYYY-MM-DD"),
            per_pack_total: product.per_pack_total
          },
        ];
        const total = values.is_pack ? +product.per_pack_total * +values.total : +values.total
        setSummary({
          total: total + +summary.total,
          price: total * parseFloat(values.cost) + summary.price,
        });
        setSearchText("")
        setProductLists(productListsData);  
        setIsSearch(false)   
        setProduct(null);
        setFieldValue("is_pack", '');
        setFieldValue("total", '');
        setFieldValue("cost", '');
      } catch (error) {
        const errorResponse = error.response;
        if (errorResponse.status === 422) {
          for (const [key, value] of Object.entries(errorResponse.data)) {
            setFieldError(key, value);
          }
        }
        setSubmitting(false);
        setBtnLoadingAddList(false);
      }
    },
  });

  const searchOnType = useCallback((value) => {
    setIsSuccess(false);
    const fetchProductsByBarcode = async (value) => {
      try {
        setSearchText(value)
        setIsSearch(false)
        const barcodeAPI = await axios.get(`barcodes/id?value=${value}`);
        const {
          data: { barcode },
        } = barcodeAPI;
        if (barcode) {
          setNotFound(false);
          setProduct(barcode.product);
          if (barcode.product && barcode.product.last_cost_price !== null) {
            formik.setFieldValue("cost", barcode.product.last_cost_price.price);
          }
          formik.setFieldValue("is_pack", barcode.product.per_pack_total > 0);
          formik.setFieldValue("total", '');
        } else {
          setProduct(null);
          setNotFound(true);
        }
        setLoading(false);
        setIsSearch(true);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
        setProduct(null);
        setNotFound(true);
      }
    };
    setLoading(true);
    fetchProductsByBarcode(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeSelectItem = useCallback(
    (productId, total, cost) => {
      const getSelectProducts = productLists;
      const filterProduct = getSelectProducts.filter(
        (p) => p.product_id !== productId
      );
      setProductLists(filterProduct);

      setSummary({
        total: summary.total - +total,
        price: summary.price - +total * parseFloat(cost),
      });
    },
    [productLists, summary.price, summary.total]
  );

  const onClickCreateWarehouse = useCallback(async () => {
    if (loading) {
      return;
    }
    if (productLists.length === 0) {
      alert.show("กรุณาเพิ่มสินค้าเข้าคลังก่อนบันทึก !");
      return;
    }
    setBtnLoading(true);
    setLoading(true);
    try {
      await axios.post(`warehouse`, {
        product_lists: productLists,
      });
      setLoading(false);
      setBtnLoading(false);
      setProductLists([]);
      setIsSuccess(true);
      setSummary({total: 0, price: 0})
      window.scrollTo(0, 0);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert.error("มีข้อผิดพลาดเกิดขึ้น.");
      }
      setLoading(false);
      setBtnLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, productLists]);

  return (
    <>
      <LoadingComponent isLoading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">จัดการสินค้าเข้าคลัง</h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <div className="card-body">
              <div className="row pb-3">
                {isSuccess && (
                  <div className="col-12">
                    <div className="alert alert-success" role="alert">
                      บันทึกข้อมูลสำเร็จ.
                    </div>
                  </div>
                )}

                <div className="col-sm-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>บาร์โคด</label>
                        <SearchComponent value={searchText} onSearch={searchOnType} />
                      </div>
                    </div>
                    {isSearch &&
                      (notFound || !product ? (
                        <div className="alert alert-danger" role="alert">
                          ไม่พบสินค้า.
                        </div>
                      ) : (
                        <div className="col-12">
                          <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                              <div className="col-sm-12 col-md-6">
                                <label>ชื่อสินค้า </label>: {product.name}
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <label>ซื้อมาจาก </label>:{" "}
                                {product.agent_embed || '-'}
                              </div>
                              <div className="col-12 pt-2">
                                <div className="form-group d-flex flex-column">
                                  <label>วันที่ทำรายการ</label>
                                  <DatePicker
                                    name="import_date"
                                    placeholderText={moment(
                                      formik.values.import_date
                                    )
                                      .add(543, "years")
                                      .format("D MMMM YYYY ")}
                                    className="form-control"
                                    onChange={(value) =>
                                      formik.setFieldValue("import_date", value)
                                    }
                                    value={formik.values.import_date}
                                    locale="th"
                                    dateFormat="dd/MM/yyyy"
                                    autoComplete="off"
                                    renderCustomHeader={(props) =>
                                      customHeader({ ...props })
                                    }
                                  />
                                  {formik.errors.import_date && (
                                    <span className="text-danger">
                                      {formik.errors.import_date}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <label>ผู้ทำรายงาน</label> : {owner}
                              </div>
                              <div className="col-sm-12 col-md-6">
                                <label>นำเข้าแบบแพ็ค / ชิ้น</label>
                                <div className="row">
                                  <div className="col-sm-12 col-md-6">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="1"
                                        name="is_pack"
                                        disabled={!formik.values.is_pack}
                                        checked={formik.values.is_pack}
                                        onChange={(e) => {
                                          setTimeout(() => {
                                            formik.setFieldValue("is_pack", 1);
                                          }, 100);
                                        }}
                                      />
                                      <label className="form-check-label">
                                        แพ็ค
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-sm-12 col-md-6">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        value="0"
                                        name="is_pack"
                                        disabled={formik.values.is_pack}
                                        checked={!formik.values.is_pack}
                                        onChange={(e) => {
                                          setTimeout(() => {
                                            formik.setFieldValue("is_pack", 0);
                                          }, 100);
                                        }}
                                      />
                                      <label className="form-check-label">
                                        ชิ้น
                                      </label>
                                    </div>
                                  </div>
                                  {formik.errors.is_pack && (
                                    <span className="text-danger">
                                      {formik.errors.is_pack}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 pt-2">
                                <div className="form-group">
                                  <label>จำนวนนำเข้า</label>
                                  <input
                                    type="text"
                                    name="total"
                                    className="form-control text-right"
                                    placeholder="กรอกจำนวนนำเข้า"
                                    onChange={(e) => {
                                      const {
                                        target: { value },
                                      } = e;
                                      formik.setFieldValue("total", value);
                                    }}
                                    onBlur={() => formik.setFieldTouched("is_pack", true)}
                                    value={formik.values.total}
                                  />

                                  {formik.errors.total && (
                                    <span className="text-danger">
                                      {formik.errors.total} นี่ๆ
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 pt-2">
                                <div className="form-group">
                                  <label>ราคาต้นทุน</label>
                                  <input
                                    name="cost"
                                    type="text"
                                    className="form-control text-right"
                                    placeholder="กรอกราคาต้นทุน"
                                    onChange={formik.handleChange}
                                    value={formik.values.cost}
                                  />
                                  {formik.errors.cost && (
                                    <span className="text-danger">
                                      {formik.errors.cost}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 text-center">
                                {btnLoadingAddList ? (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    <i className="fas fa-spinner fa-spin"></i>
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    + เพิ่มรายการ
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <Carousel images={product ? product.images : []} />
                </div>
              </div>
              {productLists.length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="table-responsive text-nowrap ">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>ลำดับ</th>
                            <th>รายการสินค้า</th>
                            <th className="text-right">จำนวน (ชิ้น)</th>
                            <th className="text-right">ราคาต้นทุน (บาท)</th>
                            <th className="text-center">ลบ</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productLists.map((product, idx) => {
                            return (
                              <tr key={product.product_id}>
                                <td>{idx + 1}</td>
                                <td>{product.name}</td>
                                <td className="text-right">{product.is_pack ? +product.total * +product.per_pack_total : product.total}</td>
                                <td className="text-right">{product.cost}</td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() =>
                                      removeSelectItem(
                                        product.product_id,
                                        product.is_pack ? +product.total * +product.per_pack_total : product.total,
                                        product.cost
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                          {productLists.length > 0 && (
                            <tr>
                              <td className="text-center" colSpan="2">
                                จำนวนทั้งหมด / ราคา
                              </td>
                              <td className="text-right">{summary.total}</td>
                              <td className="text-right">
                                {parseFloat(summary.price).toLocaleString(undefined, {minimumFractionDigits: 2})}
                              </td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="card-footer text-center">
              {productLists.length > 0 &&
                (btnLoading ? (
                  <button type="submit" className="btn btn-primary">
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    <i className="fas fa-save"></i> สรุปรายการเข้าคลัง
                  </button>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                ยืนยันการเพิ่มข้อมูล
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">คุณต้องการเพิ่มข้อมูลหรือไม่ ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onClickCreateWarehouse()}
                data-dismiss="modal"
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  owner: `${state.user.name} ${state.user.lastname}`,
});

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);
