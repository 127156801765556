import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "../../config/service";
import moment from "moment";
import { Link } from "react-router-dom";
import LoadingComponent from "../other/loading";
import SearchComponent from "../input/search";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setTitleHeader } from "../../actions/menus";
import { useAlert } from "react-alert";

const Lists = ({ setTitleHeader }) => {
  const alert = useAlert();
  const [isLoading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [customers, setCustomer] = useState([]);
  const [seletedCustomer, setSelectedCustomer] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [customerTotal, setCustomerTotal] = useState(0);
  const [pageNow, setPageNow] = useState(0);
  const isMounted = useRef(true);

  const fetchCustomers = useCallback(
    async (page = 1, search = "") => {
      try {
        const customersAPI = await axios.get(
          `customer?page=${page}&search=${search}`
        );
        const {
          data: {
            customers: { data, last_page },
          },
        } = customersAPI;
        setCustomerTotal(last_page);
        setCustomer(data);
        setLoading(false);
        setPageNow(page);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
        console.log(error);
      }
    },
    [alert]
  );

  const onClickDeleteCustomer = useCallback(
    async (customer) => {
      if (deleting) {
        alert.show("กำลังลบข้อมูลก่อนหน้าอยู่กรุณารอสักครู่.");
        return;
      }
      if (customer) {
        setDeleting(true);
        setLoading(true);
        try {
          await axios.post(`customer/delete/${customer.id}`);
          await fetchCustomers();
          setDeleting(false);
          setSelectedCustomer(null);
          setLoading(false);
          alert.success("ลบข้อมูลสำเร็จ.");
        } catch (error) {
          if (error.response && error.response.status === 500) {
            alert.error("มีข้อผิดพลาดเกิดขึ้น.");
          }
          setDeleting(false);
          setSelectedCustomer(null);
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleting, fetchCustomers]
  );

  const handlePageClick = useCallback(
    async (data) => {
      setLoading(true);
      fetchCustomers(data.selected + 1, searchText);
    },
    [fetchCustomers, searchText]
  );

  useEffect(() => {
    fetchCustomers();
    setTitleHeader({
      title: "จัดการข้อมูลลูกค้า",
      href: "/customers",
    });
    return () => {
      isMounted.current = false;
    };
  }, [fetchCustomers, setTitleHeader]);

  const searchOnType = useCallback(
    async (value) => {
      setLoading(true);
      setSearchText(value);
      fetchCustomers(1, value);
    },
    [fetchCustomers]
  );

  return (
    <>
      <div>
        <LoadingComponent isLoading={isLoading} />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            {/* Search form */}
            <SearchComponent onSearch={searchOnType} />
          </div>
          <div className="col-sm-12 col-md-6 text-md-right">
            <Link to="/customers/add">
              <button type="button" className="btn btn-success">
                + เพิ่มข้อมูล
              </button>
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="card">
              {/* /.card-header */}
              <div className="card-body p-0">
                <div className="table-responsive text-nowrap">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="text-center">จัดการ</th>
                        <th>ลำดับ</th>
                        <th>ชื่อลูกค้า</th>
                        <th>เบอร์โทรศัพท์</th>
                        <th>ที่อยู่</th>
                        <th>แก้ไขล่าสุด</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customers.map((customer, idx) => {
                        return (
                          <tr key={customer.id}>
                            <td className="text-center d-flex justify-content-around">
                              <Link to={`/customers/edit/${customer.id}`}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </Link>
                              {!customer.has_order && (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={() => setSelectedCustomer(customer)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              )}
                            </td>
                            <td>{(pageNow - 1) * 10 + (idx + 1)}</td>
                            <td>{customer.name}</td>
                            <td>{customer.tel}</td>
                            <td>{customer.address}</td>
                            <td>
                              {moment(customer.updated_at).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ReactPaginate
                previousLabel={"ก่อนหน้า"}
                nextLabel={"ต่อไป"}
                pageCount={customerTotal}
                pageRangeDisplayed={5}
                previousClassName={"page-item"}
                marginPagesDisplayed={1}
                nextClassName={"page-item"}
                pageClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </nav>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                ยืนยันการลบข้อมูลลูกค้า
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">คุณต้องการลบข้อมูลลูกค้าหรือไม่ ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onClickDeleteCustomer(seletedCustomer)}
                data-dismiss="modal"
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(null, mapDispatchToProps)(Lists);
