import React from "react";
import axios from "../../config/service";
import { withRouter, Link } from "react-router-dom";
import { Formik } from "formik";
import Yup from "../../utils/yup";
import ErrorFocus from "../errors/ErrorFocus";
import LoadingComponent from "../other/loading";
import { connect } from "react-redux";
import { setTitleHeader } from "../../actions/menus";
import { withAlert } from "react-alert";

const thaiPermissions = {
  "manage-users": "สามารถจัดการผู้ใช้ระบบ",
  "manage-customers": "สามารถจัดการข้อมูลลูกค้า",
  "manage-products": " สามารถ เพิ่ม ลบ แก้ไขรายการสินค้าทั้งหมด",
  "manage-treasury": "สามารถค้นหาตำแหน่งสินค้า",
  "manage-warehouse": "สามารถจัดการสินค้าเข้าคลัง",
  "manage-price": "สามารถกำหนดราคาขาย",
  "manage-sticker": "สามารถพิมพ์สติกเกอร์สินค้า",
  "manage-seller": "สามารถขายหน้าร้าน",
  "manage-refund": "สามารถซื้อของคืนเข้าระบบ",
  "manage-expense": "สามารถจัดการรายจ่ายอื่นๆ",
  'see-info-cost': 'สามารถดูข้อมูลต้นทุน',
  "manage-stock-split": 'สามารถจัดการแตกสินค้า'
};

const validationSchema = Yup.object().shape({
  username: Yup.string().trim().required("กรุณากรอกชื่อผู้ใช้ระบบ"),
  name: Yup.string().trim().required("กรุณากรอกชื่อพนักงาน"),
  lastname: Yup.string().trim().required("กรุณากรอกนามสกุลพนักงาน"),
  telephone: Yup.string()
    .trim()
    .max(10, "เบอร์โทรต้องไม่เกิน 10 ตัวอักษร")
    .required("กรุณากรอกเบอร์โทรศัพท์"),
  email: Yup.string()
    .trim()
    .email("กรุณากรอกอีเมลที่ถูกต้อง")
    .required("กรุณากรอกอีเมล"),
  password: Yup.string()
    .trim()
    .min(8, "รหัสผ่านควรมีจำนวน 8 ตัวขึ้นไป")
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
    //   "รหัสผ่านควรมี ตัวอักษร เล็ก, ใหญ่, ตัวเลข และมีความยาวอย่างน้อย 8 ตัว"
    // )
    .required("กรุณากรอกรหัสผ่าน"),
  confirm_password: Yup.string()
    .equalTo(Yup.ref("password"), "รหัสผ่านไม่ตรงกัน")
    .required("กรุณายืนยันรหัสผ่าน"),
});

class AddForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      confirm_password: "",
      name: "",
      lastname: "",
      telephone: "",
      email: "",
      permissions: [],
      permissionsData: [],
      isLoading: false,
      isLoadingFetch: true,
    };
  }

  componentDidMount() {
    this.fetchPermissions();
    this.props.setTitleHeader({
      title: "เพิ่มข้อมูลพนักงาน",
      href: `/users/add`,
    });
  }

  fetchPermissions = async () => {
    try {
      const permissionsAPI = await axios.get(`permissions`);
      const {
        data: { permissions },
      } = permissionsAPI;
      this.setState({
        permissionsData: permissions,
        isLoadingFetch: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 500) {
        this.props.alert.error("มีข้อผิดพลาดเกิดขึ้น.");
      }
    }
  };

  handlePermissionChange = (event) => {
    const value = event.target.value;
    let permission = this.state.permissions;
    if (event.target.checked) {
      this.setState((prevState) => ({
        permissions: [...prevState.permissions, value],
      }));
    } else {
      const index = permission.indexOf(value);
      if (index > -1) {
        permission.splice(index, 1);
        this.setState({
          permissions: permission,
        });
      }
    }
  };

  saveEmployee = async (value, { setFieldError, setSubmitting }) => {
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    try {
      const {
        username,
        password,
        confirm_password,
        name,
        lastname,
        telephone,
        email,
      } = value;
      const employeeData = {
        username: username,
        password: password,
        password_confirmation: confirm_password,
        name: name,
        lastname: lastname,
        telephone: telephone,
        email: email,
        permissions: this.state.permissions,
      };
      await axios.post(`employee`, employeeData);
      this.props.history.push("/users");
    } catch (error) {
      console.log(error);
      if (error.response.status === 422) {
        for (const [key, value] of Object.entries(error.response.data)) {
          setFieldError(key, value);
        }
        setSubmitting(false);
      }
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { isLoading, isLoadingFetch } = this.state;
    return (
      <>
        <LoadingComponent isLoading={isLoadingFetch || isLoading} />
        <Formik
          onSubmit={this.saveEmployee}
          initialValues={this.state}
          validationSchema={validationSchema}
        >
          {(props) => {
            return (
              <>
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">ฟอร์มเพิ่มข้อมูลพนักงาน</h3>
                  </div>
                  <form
                    className="form-horizontal"
                    onSubmit={props.handleSubmit}
                    autoComplete="off"
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label>ชื่อผู้ใช้ระบบ</label>
                                <input
                                  type="text"
                                  name="username"
                                  className={`form-control ${
                                    props.errors.username ? "is-invalid" : ""
                                  }`}
                                  placeholder="กรุณากรอกชื่อผู้ใช้"
                                  value={props.values.username}
                                  onChange={props.handleChange}
                                />
                                {props.errors.username && (
                                  <span className="text-danger">
                                    {props.errors.username}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>รหัสผ่าน</label>
                                <input
                                  type="password"
                                  name="password"
                                  className={`form-control ${
                                    props.errors.password ? "is-invalid" : ""
                                  }`}
                                  placeholder="กรุณากรอกรหัสผ่าน"
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                  autoComplete="new-password"
                                />
                                {props.errors.password && (
                                  <span className="text-danger">
                                    {props.errors.password}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>ยืนยันรหัสผ่าน</label>
                                <input
                                  type="password"
                                  name="confirm_password"
                                  className={`form-control ${
                                    props.errors.confirm_password
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="กรุณากรอกยืนยันรหัสผ่าน"
                                  value={props.values.confirm_password}
                                  onChange={props.handleChange}
                                />
                                {props.errors.confirm_password && (
                                  <span className="text-danger">
                                    {props.errors.confirm_password}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label>ชื่อพนักงาน</label>
                                <input
                                  type="text"
                                  name="name"
                                  className={`form-control ${
                                    props.errors.name ? "is-invalid" : ""
                                  }`}
                                  placeholder="กรุณากรอกชื่อพนักงาน"
                                  value={props.values.name}
                                  onChange={props.handleChange}
                                />
                                {props.errors.name && (
                                  <span className="text-danger">
                                    {props.errors.name}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                              <div className="form-group">
                                <label>นามสกุลพนักงาน</label>
                                <input
                                  type="text"
                                  name="lastname"
                                  className={`form-control ${
                                    props.errors.lastname ? "is-invalid" : ""
                                  }`}
                                  placeholder="กรุณากรอกนามสกุลพนักงาน"
                                  value={props.values.lastname}
                                  onChange={props.handleChange}
                                />
                                {props.errors.lastname && (
                                  <span className="text-danger">
                                    {props.errors.lastname}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>เบอร์โทรศัพท์</label>
                                <input
                                  type="text"
                                  name="telephone"
                                  className={`form-control ${
                                    props.errors.telephone ? "is-invalid" : ""
                                  }`}
                                  placeholder="กรุณากรอกเบอร์โทรศัพท์"
                                  value={props.values.telephone}
                                  onChange={props.handleChange}
                                />
                                {props.errors.telephone && (
                                  <span className="text-danger">
                                    {props.errors.telephone}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="form-group">
                                <label>อีเมล</label>
                                <input
                                  type="text"
                                  name="email"
                                  className={`form-control ${
                                    props.errors.email ? "is-invalid" : ""
                                  }`}
                                  placeholder="กรุณากรอกอีเมล"
                                  value={props.values.email}
                                  onChange={props.handleChange}
                                />
                                {props.errors.email && (
                                  <span className="text-danger">
                                    {props.errors.email}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ErrorFocus />
                        <div className="col-sm-12 col-md-6">
                          <div className="row"></div>
                          <div className="col-12">
                            <div class="form-group">
                              <label>สิทธิการใช้งานระบบ</label>
                              {this.state.permissionsData.map((permission) => {
                                return (
                                  <div class="form-check" key={permission.id}>
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      value={permission.name}
                                      onChange={(e) =>
                                        this.handlePermissionChange(e)
                                      }
                                    />
                                    <label class="form-check-label">
                                      {thaiPermissions[permission.name]}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      {isLoading ? (
                        <button type="button" className="btn btn-primary">
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          <i className="fas fa-save"></i> บันทึก
                        </button>
                      )}
                      <Link to="/users">
                        <button
                          type="button"
                          className="btn btn-secondary float-right"
                          disabled={isLoading}
                        >
                          <i className="fas fa-window-close"></i> ยกเลิก
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(withAlert()(AddForm)));
