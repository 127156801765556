import React, { useEffect, useState, useCallback, useRef } from 'react'
import moment from 'moment'
import axios from "../../config/service";
import { setTitleHeader } from "../../actions/menus";
import LoadingComponent from "../other/loading";
import SearchComponent from "../input/search";
import ProductDetailModal from "../modals/ProductDetailModal";
import ConfirmModal from "../modals/ConfirmModal";
import OrderDetailModal from "../modals/OrderDetailModal";
import OrderPrint from "../print/OrderPrint";
import {
  PAYMENT_CHANNEL,
  PAYMENT_CHANNEL_DISPLAY,
} from "../../constants/paymentChannel";
import { ORDER_STATUS } from "../../constants/orderStatus";
import {
  matchStateToTermAgent
} from "../../utils/autocomplete";

import { connect } from "react-redux";
import { useAlert } from "react-alert";
import ReactPaginate from "react-paginate";
import currency from "currency.js";
import * as Yup from "yup";
import ReactToPrint from "react-to-print";
import Autocomplete from "react-autocomplete";

const schema = (max) => {
  return Yup.object().shape({
    choose_total: Yup.number("กรุณาเฉพาะตัวเลข")
      .min(1, "กรุณากรอกจำนวนอย่างน้อย 1")
      .max(max, "จำนวนที่สามารถกรอกได้สูงสุดคือ " + max)
      .typeError("กรุณาเฉพาะตัวเลข")
      .integer("กรุณาเฉพาะตัวเลข")
      .required("กรุณากรอกยอดสูงสุดในการสต็อค"),
  });
};

const orderDetailObj = {
  customer_type: {
    value: "",
    display: "",
  },
  payment_channel: {
    value: "",
    display: "",
  },
  customer: {
    name: "",
    address: "",
    tel: "",
  },
  date: {
    value: "",
    display: "",
  },
  staff_name: "",
  products: [],
}

const customerTypeClass = {
  0: 'border-red',
  1: 'border-yellow',
  2: 'border-green'
}

const Index = ({
  setTitleHeader,
  owner
}) => {
  const alert = useAlert();
  const [searchProductValue, setSearchProductValue] = useState('')
  const [isLoading, setLoading] = useState(true);
  const [customerTypesItems, setCustomerTypeItems] = useState([]);
  const [paymentChannel, setPaymentChannel] = useState(PAYMENT_CHANNEL.CASH);
  const [ownerName, setOwnerName] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customers, setCustomers] = useState([]);
  const [selectProducts, setSelectProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [cacheProduct, setCacheProduct] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [productDetail, setProductDetail] = useState({
    name: "",
    description: "",
    buyer: "",
    barCode: "",
    partSpec: "",
    stockPosition: "",
    maximumStock: "",
    warningStock: "",
    packTotal: "",
    pTotal: "",
    vat: "",
  });
  const [priceTotal, setPriceTotal] = useState(0)
  const [notFoundProductSelect, setNotFoundProductSelect] = useState(false);
  const [pageNow, setPageNow] = useState(0);
  const [isDisableInput, setIsDisableInput] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [orders, setOrders] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  const [selectOrder, setSelectOrder] = useState({
    id: "",
  });
  const [orderDetail, setOrderDetail] = useState(orderDetailObj);
  const [dealDate, setDealDate] = useState(new Date());
  const [disableEndTransaction, setDisableEndTransaction] = useState(true);
  const [disablePrintTransaction, setDisabledPrintTransaction] = useState(true);
  const [disableSaveTransaction, setDisabledSaveTransaction] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const componentPrint = useRef();
  const printOrderDetailBtn = useRef(null);
  const confirmOrderDetailBtn = useRef(null);
  const isMounted = useRef(true);

  const setLoadingTrue = () => {
    setLoading(true);
  };

  const setLoadingFalse = () => {
    setLoading(false);
  };

  const fetchCustomer = useCallback(
    async (page = 1, search = "") => {
      try {
        setLoadingTrue();
        const customerAPI = await axios.get(
          `customer/all?search=${search}`
        );
        const {
          data: {
            customers: data
          },
        } = customerAPI;
        if (isMounted.current) {
          // setCustomerTotal(last_page);
          const cashCustomer = data.find(d => d.name === "สด")
          if(cashCustomer) {
            setCustomerType(cashCustomer.customer_type_id)
            setCustomerName(cashCustomer.name)
            setSelectedCustomer(cashCustomer.id)
            setOrderDetail({
              ...orderDetail,
              customer: {
                name: cashCustomer.name,
                address: cashCustomer.address,
                tel: cashCustomer.tel,
              }
            })
          }
          setCustomers(data)
        }
        setLoadingFalse();
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoadingFalse();
      }
    },
    [alert, orderDetail]
  );

  const fetchProducts = useCallback(async (page = 1, search = "", fromSearchFunc = false) => {
    try {
      setSearchProductValue(search)
      const productsAPI = await axios.get(
        `products?page=${page}&search=${search}&is_check_stock=1`
      );
      const {
        data: {
          products: { data, last_page },
        },
      } = productsAPI;
      if(fromSearchFunc && data.length === 1) {
        const productId = data[0].id;
        let getSelectProducts = selectProducts;
        if (
          !getSelectProducts.find((product) => product.id === productId)
        ) {
          const setProduct = {
            ...data[0],
            choose_total: data[0].per_pack_total > 0 ? data[0].per_pack_total * 1 : 1,
            error: false,
            errorMessage: "",
          };
          getSelectProducts = [...getSelectProducts, setProduct];
          setSelectProduct(getSelectProducts);
          setSearchProductValue('')
        } else {
          const oldProduct = getSelectProducts.map((product) => {
            if(product.id === productId) {
              return {
                ...product,
                choose_total: product.per_pack_total > 0 ? product.choose_total + (product.per_pack_total * 1) : product.choose_total + 1,
                error: false,
                errorMessage: "",
              }
            }
            return product
          })
          setSelectProduct(oldProduct);
          setSearchProductValue('')
        }
      }
      setProductTotal(last_page);
      setProducts(data);
      setPageNow(page);
      setLoadingFalse();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert.error("มีข้อผิดพลาดเกิดขึ้น.");
      }
      setLoadingFalse();
      console.log(error);
    }
  }, [alert, selectProducts])

  const fetchOrders = useCallback(
    async (page = 1, search = "") => {
      try {
        setLoadingTrue();
        const orderAPI = await axios.get(
          `orders?page=${page}&search=${search}`
        );
        const {
          data: {
            orders: { data, last_page },
          },
        } = orderAPI;
        setOrderTotal(last_page);
        setOrders(data);
        setLoadingFalse();
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoadingFalse();
      }
    },
    [alert]
  );

  useEffect(() => {
    setTitleHeader({
      title: "ขายหน้าร้าน",
      href: "/deal",
    });
    const fetchCustomerType = async () => {
      try {
        const getCustomerTypes = await axios.get("customer/type");
        setCustomerTypeItems(getCustomerTypes.data.customer_type);
        setLoadingFalse();
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        console.log(error);
      }
    };
    fetchCustomerType();
    fetchCustomer();
    fetchProducts();
    fetchOrders();
    setOwnerName(owner);
    setTimeout(() => {
      document.getElementById("pushmenu").click();
    }, 1000);
    return () => {
      isMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const prices = selectProducts.reduce((a, b) => {
      const productPrice = b.product_prices.find(
        (value) =>
          value.customer_type_id === customerType
      );
      return productPrice ? (productPrice.price * b.choose_total) + a : a
    }, 0);
    setPriceTotal(prices >= 0 ? prices : 0)
  }, [customerType, selectProducts])

  const onChangeCustomerType = useCallback(
    async (value) => {
      setLoadingTrue();
      setCustomerType(value);
      fetchProducts(pageNow, searchProductValue);
    },
    [fetchProducts, pageNow, searchProductValue]
  );

  const searchProductOnType = useCallback(
    (value) => {
      setLoadingTrue();
      fetchProducts(1, value, true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetchProducts]
  );

  const selectProduct = useCallback(
    (event, product) => {
      const isChecked = event.target.checked;
      const productId = product.id;
      let getSelectProducts = selectProducts;
      if (isChecked) {
        if (
          !getSelectProducts.find((product) => product.id === productId)
        ) {
          const setProduct = {
            ...product,
            choose_total: product.per_pack_total > 0 ? product.per_pack_total * 1 : 1,
            error: false,
            errorMessage: "",
          };
          getSelectProducts = [...getSelectProducts, setProduct];
          setSelectProduct(getSelectProducts);
        } else {
          alert.show("สินค้านี้มีอยู่ในรายการที่เลือกเรียบร้อยแล้ว.");
        }
      } else {
        const filterProduct = getSelectProducts.filter(
          (product) => product.id !== productId
        );
        setSelectProduct(filterProduct);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectProducts]
  );

  const handleProductPageClick = useCallback(
    async (data) => {
      setLoadingTrue();
      fetchProducts(data.selected + 1, searchProductValue);
    },
    [fetchProducts, searchProductValue]
  );

  const handleTotalChange = (value, idx) => {
    let getSelectProducts = [...selectProducts];
    const getSchema = schema(getSelectProducts[idx].current_stock);
    getSchema
      .validate({
        choose_total: value,
      })
      .then(() => {
        getSelectProducts[idx].choose_total = +value;
        getSelectProducts[idx].error = false;
        getSelectProducts[idx].errorMessage = "";
        setSelectProduct(getSelectProducts);
      })
      .catch((error) => {
        getSelectProducts[idx].choose_total = +value;
        getSelectProducts[idx].error = true;
        getSelectProducts[idx].errorMessage = error.message;
        setSelectProduct(getSelectProducts);
      });
  };

  const removeSelectItem = useCallback(
    (productId) => {
      const getSelectProducts = selectProducts;
      setCacheProduct(getSelectProducts)
      const filterProduct = getSelectProducts.filter(
        (product) => product.id !== productId
      );
      setSelectProduct(filterProduct);
    },
    [selectProducts]
  );

  const submitOrderDetail = useCallback(async () => {
    const order = selectOrder;
    setSelectOrder(order);
    setDisabledSaveTransaction(order.status !== ORDER_STATUS.DRAFT);
    setDisableEndTransaction(order.status !== ORDER_STATUS.DRAFT);
    setDisabledPrintTransaction(order.status !== ORDER_STATUS.SUCCESS);
    setIsDisableInput(order.status === ORDER_STATUS.SUCCESS);
    let selectedProduct = [];
    await Promise.all(
      order.order_products.map(async (value) => {
        const product = await axios.get(`products/${value.product_id}`);
        const setProduct = {
          ...product.data.product,
          choose_total: value.amount,
          error: false,
          errorMessage: "",
        };
        selectedProduct = [...selectedProduct, setProduct];
      })
    );
    setCustomerName(order.customer_name)
    setSelectProduct(selectedProduct);
    setDealDate(order.order_date);
    setOwnerName(`${order.staff_name} ${order.staff_lastname}`);
    fetchCustomer(1, order.customer_name);
    setSelectedCustomer(order.customer_id);
    setCustomerType(order.customer_type_id);
    setPaymentChannel(order.payment_channel);
    setOrderDetail({
      customer_type: {
        value: order.customer_type_id,
        display: customerTypesItems.find(
          (value) => value.id === order.customer_type_id
        ).type,
      },
      payment_channel: {
        value: order.payment_channel,
        display: PAYMENT_CHANNEL_DISPLAY[order.payment_channel],
      },
      date: {
        value: order.order_date,
        display: moment(order.order_date)
          .add(543, "years")
          .format("D MMMM YYYY "),
      },
      staff_name: ownerName,
      products: selectProducts,
      customer: {
        name: order.customer_name,
        address: order.customer_address,
        tel: order.customer_tel,
      },
    });
  }, [
    customerTypesItems,
    fetchCustomer,
    ownerName,
    selectOrder,
    selectProducts,
  ]);

  const clearAllState = () => {
    fetchCustomer();
    setCustomerType('');
    setPaymentChannel(PAYMENT_CHANNEL.CASH);
    setSelectOrder({
      id: "",
    });
    setDisabledSaveTransaction(false);
    setDisableEndTransaction(true);
    setDisabledPrintTransaction(true);
    setSelectProduct([]);
    setCacheProduct([])
    setDealDate(new Date());
    setOwnerName(owner);
    setSelectedCustomer("");
    setIsDisableInput(false)
    setOrderDetail(orderDetailObj)
  };

  const submit = async () => {
    try {
      setLoadingButton(true);
      setLoadingTrue();
      const productFormat = selectProducts.map((product) => {
        return {
          product_id: product.id,
          amount: product.choose_total,
        };
      });
      const order = await axios.post("orders", {
        customer_type_id: customerType,
        payment_channel: paymentChannel,
        customer_id: selectedCustomer,
        order_date: moment(dealDate).format("YYYY-MM-DD HH:mm:ss"),
        products: productFormat,
        order_id: selectOrder.id ? selectOrder.id : 0
      });
      setSelectOrder({
        id: order.data.data.id,
      });
      setDisabledSaveTransaction(order.data.data.status !== ORDER_STATUS.DRAFT);
      setDisableEndTransaction(order.data.data.status !== ORDER_STATUS.DRAFT);
      setDisabledPrintTransaction(
        order.data.data.status !== ORDER_STATUS.SUCCESS
      );
      setLoadingFalse();
      setLoadingButton(false);
      alert.success("เพิ่มข้อมูลสำเร็จ.");
      fetchOrders();
      fetchProducts();
      document.getElementById("history-tab").click()
    } catch (error) {
      if(error.response && error.response.status === 400) {
         alert.error(error.response.data.message);
      }
      setLoadingFalse();
      setLoadingButton(false);
    }
  };

  const submitEndTransaction = async () => {
    try {
      setLoadingTrue();
      setLoadingButton(true);
      const order = await axios.post(`orders/${selectOrder.id}`);
      setSelectOrder({
        id: order.data.data.id,
      });
      setDisabledSaveTransaction(order.data.data.status !== ORDER_STATUS.DRAFT);
      setDisableEndTransaction(order.data.data.status !== ORDER_STATUS.DRAFT);
      setDisabledPrintTransaction(
        order.data.data.status !== ORDER_STATUS.SUCCESS
      );
      setLoadingFalse();
      alert.success("เพิ่มข้อมูลสำเร็จ.");
      fetchOrders();
      fetchProducts();
      setLoadingButton(false);
      setIsDisableInput(true)
      document.getElementById("history-tab").click()
      printOrderDetailBtn.current.click();
    } catch (error) {
      if(error.response && error.response.status === 400) {
        alert.error(error.response.data.message);
     }
      setLoadingFalse();
      setLoadingButton(false);
    }
  };

  const submitSell = useCallback((force = false) => {
    const forceSetOrderDetail = () => {
      setOrderDetail({
        customer: orderDetail.customer,
        customer_type: {
          value: customerType,
          display: customerTypesItems.find((value) => value.id === customerType)
            .type,
        },
        payment_channel: {
          value: paymentChannel,
          display: PAYMENT_CHANNEL_DISPLAY[paymentChannel],
        },
        date: {
          value: dealDate,
          display: moment(dealDate).add(543, "years").format("D MMMM YYYY "),
        },
        staff_name: ownerName,
        products: selectProducts,
      });
    }
    if(force) {
      forceSetOrderDetail()
      if(cacheProduct.length > 0) {
        setSelectProduct(cacheProduct)
      }
      return;
    }
    if (!selectedCustomer) {
      const errorElement = document.getElementById("find_customer");
      if (errorElement) {
        errorElement.focus();
      }
    }
    if (selectProducts.length <= 0) {
      setNotFoundProductSelect(true);
    } else {
      setNotFoundProductSelect(false);
    }
    if (!selectedCustomer || selectProducts.length <= 0) {
      return;
    }
    let isError = false;
    for (const product of selectProducts) {
      if (product.error) {
        isError = true;
        break;
      }
    }
    setCanSubmit(isError);
    if (isError) {
      return;
    } else {
      forceSetOrderDetail()
    }
  }, [cacheProduct, customerType, customerTypesItems, dealDate, orderDetail.customer, ownerName, paymentChannel, selectProducts, selectedCustomer]);

return (
<>
  <LoadingComponent isLoading={isLoading} />
  <div className="row">
    <div className="col-sm-12 col-md-8 pl-0">
      <div className="card card-info">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <span>{`${moment().format('วันdddd ที่DD MMMM')} ${+moment().format("YYYY") + 543}`}</span>
            <span>{owner}</span>
          </div>
        </div>
        <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className="row">
              <div className="col-12 col-md-6">
                <label>ประเภทลูกค้า</label>
                <div>
                {
                                customerTypesItems.map((value, index) => {
                                  return (
                                    <div className={"form-check form-check-inline p-1 mr-0 border-container " + customerTypeClass[index]} key={value.id}>
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      value={value.id}
                                      checked={customerType === value.id}
                                      onChange={() =>
                                        onChangeCustomerType(value.id)
                                      }
                                      disabled={customerType !== value.id || isDisableInput}
                                    />
                                    <small
                                      className="form-check-label"
                                      for="inlineRadio1"
                                    >
                                      {value.type}
                                    </small>
                                  </div>
                                  )
                                })
                              }
                </div>
              </div>
              <div className="col-12 col-md-6 text-left text-md-right">
                <label>ช่องทางการชำระเงิน</label>
                <div>
                <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              value={PAYMENT_CHANNEL.CASH}
                              checked={paymentChannel === PAYMENT_CHANNEL.CASH}
                              onChange={() =>
                                setPaymentChannel(PAYMENT_CHANNEL.CASH)
                              }
                              disabled={isDisableInput}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio1"
                            >
                              สด
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              value={PAYMENT_CHANNEL.CREDIT}
                              checked={
                                paymentChannel === PAYMENT_CHANNEL.CREDIT
                              }
                              onChange={() =>
                                setPaymentChannel(PAYMENT_CHANNEL.CREDIT)
                              }
                              disabled={isDisableInput}
                            />
                            <label
                              className="form-check-label"
                              for="inlineRadio2"
                            >
                              เครดิต
                            </label>
                          </div>
                </div>
              </div>
            </div>
          </div>
                        <div className="col-12 col-md-6 py-3">
                          <div class="border-red-sm border-container p-3">
                            <div className="form-group mb-0 ">
                                  <label>ค้นหาข้อมูลลูกค้า</label>
                                  <Autocomplete
                      value={customerName}
                      inputProps={{
                        id: "states-autocomplete",
                        class: "form-control",
                        placeholder: "รหัสบาร์โค้ด",
                      }}
                      wrapperStyle={{
                        display: "block",
                      }}
                      items={customers}
                      getItemValue={(item) => item.id}
                      shouldItemRender={matchStateToTermAgent}
                      onChange={(e) => {
                        setCustomerName(e.target.value)
                      }}
                      onSelect={(id) => {
                        const data = customers.find(d => d.id === id)
                        setCustomerType(data.customer_type_id)
                        setCustomerName(data.name)
                        setSelectedCustomer(id)
                        setOrderDetail({
                          ...orderDetail,
                          customer: {
                            name: data.name,
                            address: data.address,
                            tel: data.tel,
                          }
                        })
                      }}
                      renderMenu={(children) => (
                        <div className="menu">{children}</div>
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          className={`item ${
                            isHighlighted ? "item-highlighted" : ""
                          }`}
                          key={item.id}
                        >
                          {item.name}
                        </div>
                      )}
                    />
                                </div>
                            <div class="mt-3">
                              <label>ชื่อลูกค้า</label>
                              <span class="ml-3">{orderDetail.customer.name ? orderDetail.customer.name : '-'}</span>
                            </div>
                            <div>
                              <label>เบอร์โทร</label>
                              <span class="ml-3">{orderDetail.customer.tel ? orderDetail.customer.tel : '-'}</span>
                            </div>
                            <div>
                              <label>ที่อยู่ลูกค้า</label>
                              <span class="ml-3">{orderDetail.customer.address ? orderDetail.customer.address : '-'}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 py-3 d-flex justify-content-end">
                          <div className="total-text-title border-red border-container p-3">
                            รวมยอดเงินทั้งสิ้น {currency(priceTotal, {
                                    separator: ",",
                                    symbol: "",
                                  }).format()} บาท
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="table-responsive text-nowrap">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-center">ลำดับ</th>
                                  <th>ชื่อสินค้า</th>
                                  <th className="text-right" style={{minWidth: '300px'}}>จำนวนที่เลือก</th>
                                  <th className="text-right">ราคา</th>
                                  <th className="text-right">รวม</th>
                                  <th className="text-center">ลบ</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectProducts.length > 0 ? (
                                  selectProducts.map((product, idx) => {
                                    const productPrice = product.product_prices.find(
                                      (value) =>
                                        value.customer_type_id === customerType
                                    );
                                    return (
                                      <tr
                                        key={`selected-product-${product.id}`}
                                      >
                                        <td className="text-center">{idx + 1}</td>
                                        <td>{product.name}</td>
                                        <td className="text-right">
                                          <div class="d-flex align-items-center">
                                            <input
                                              className="form-control text-right"
                                              type="number"
                                              placeholder="จำนวน"
                                              disabled={product.per_pack_total > 0}
                                              value={product.choose_total}
                                              onChange={(e) =>
                                                handleTotalChange(e.target.value, idx)
                                              }
                                            />
                                            <button
                                              type="button"
                                              className="btn btn-primary btn-sm ml-3"
                                              onClick={() => handleTotalChange(product.per_pack_total > 0 ? product.choose_total + (product.per_pack_total * 1) : product.choose_total + 1, idx)}
                                            >
                                              <i className="fas fa-plus"></i>
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-danger btn-sm ml-3"
                                              onClick={() => handleTotalChange(product.per_pack_total > 0 ? product.choose_total - (product.per_pack_total * 1) : product.choose_total - 1, idx)}
                                              disabled={product.choose_total <= 1}
                                            >
                                              <i className="fas fa-minus"></i>
                                            </button>
                                          </div>
                                          {product.error && (
                                            <span className="text-danger">
                                              {product.errorMessage}
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {productPrice
                                            ? currency(productPrice.price, {
                                                separator: ",",
                                                symbol: "",
                                              }).format()
                                            : 0}
                                        </td>
                                        <td>
                                          {productPrice && +productPrice.price *
                                                  +product.choose_total > 0
                                            ? currency(
                                                +productPrice.price *
                                                  +product.choose_total,
                                                { separator: ",", symbol: "" }
                                              ).format()
                                            : 0}
                                        </td>
                                        <td className="text-center">
                                          <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() =>
                                              removeSelectItem(product.id)
                                            }
                                            disabled={isDisableInput}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td className="text-center" colSpan="7">
                                      ยังไม่มีสินค้าที่เลือก
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                            {notFoundProductSelect && (
                              <span className="text-danger">
                                กรุณาเลือกสินค้าอย่างน้อยหนึ่งรายการ
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
        </div>
        <div class="card-footer">
          <div className="row">
          <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-4">
                      {loadingButton ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-block h-100"
                        >
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-block h-100"
                          data-toggle={
                            !selectedCustomer ||
                            selectProducts.length <= 0 ||
                            canSubmit
                              ? ""
                              : "modal"
                          }
                          data-target={
                            !selectedCustomer ||
                            selectProducts.length <= 0 ||
                            canSubmit
                              ? ""
                              : "#orderDetailModal"
                          }
                          disabled={disableSaveTransaction}
                          onClick={() => submitSell()}
                        >
                          <i className="fas fa-save mr-1"></i>
                          บันทึกรายการ
                        </button>
                      )}
                    </div>
                    <div className="col-12 col-md-4">
                      {loadingButton ? (
                        <button
                          type="button"
                          className="btn btn-secondary btn-block h-100"
                        >
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-secondary btn-block h-100"
                          data-toggle={canSubmit ? "" : "modal"}
                          data-target={
                            canSubmit ? "" : "#orderDetailEndTransactionModal"
                          }
                          disabled={disableEndTransaction}
                          onClick={() => submitSell(true)}
                        >
                          <i class="fas fa-archive mr-1"></i>
                          จบรายการ
                        </button>
                      )}
                    </div>
                    <div className="col-12 col-md-4">
                      {loadingButton ? (
                        <button
                          type="button"
                          className="btn btn-success btn-block h-100"
                        >
                          <i className="fas fa-spinner fa-spin"></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-success btn-block h-100"
                          disabled={disablePrintTransaction}
                          onClick={() => printOrderDetailBtn.current.click()}
                        >
                          <i class="fas fa-print mr-1"></i>
                          พิมพ์ใบแจ้งยอด
                        </button>
                      )}
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-4 pr-0">
      <div className="card card-info">
        <div className="card-header">
          <ul class="nav nav-tabs card-header-tabs" id="myTab" role="tablist">
            {/* <li class="nav-item">
              <a class="nav-link active" id="customer-tab" data-toggle="tab" href="#customer" role="tab" aria-controls="customer" aria-selected="true">ลูกค้า</a>
            </li> */}
            <li class="nav-item">
              <a class="nav-link active" id="product-tab" data-toggle="tab" href="#product" role="tab" aria-controls="product" aria-selected="false">สินค้า</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">ประวัติการขาย</a>
            </li>
          </ul>
        </div>
        <div className="card-body">
          <div class="tab-content" id="myTabContent">
            {/* <div class="tab-pane fade show active" id="customer" role="tabpanel" aria-labelledby="customer-tab">
              <div className="row">
              <div className="col-12">
                          <label>ค้นหาข้อมูลลูกค้า</label>
                          <div className="row">
                            <div className="col-9">
                              <div className="form-group">
                                <input
                                  id="find_customer"
                                  type="text"
                                  className="form-control"
                                  value={customerName}
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      fetchCustomer(1, customerName);
                                    }
                                  }}
                                  onChange={(e) =>
                                    setCustomerName(e.target.value)
                                  }
                                  disabled={isDisableInput}
                                />
                              </div>
                            </div>
                            <div className="col-3">
                              <button
                                type="button"
                                className="btn btn-primary btn-block"
                                onClick={() => fetchCustomer(1, customerName)}
                                disabled={isDisableInput}
                              >
                                <i className="fas fa-search mr-1"></i>
                                ค้นหา
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="table-responsive text-nowrap">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>เลือก</th>
                                  <th>ชื่อร้าน</th>
                                  <th>ที่อยู่</th>
                                  <th>เบอร์โทร</th>
                                </tr>
                              </thead>
                              <tbody>
                                {customers.map((customer) => {
                                  return (
                                    <tr key={customer.id}>
                                      <td>
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            value={customer.id}
                                            checked={
                                              selectedCustomer === customer.id
                                            }
                                            onChange={() => {
                                              setSelectedCustomer(customer.id);
                                              setOrderDetail({
                                                ...orderDetail,
                                                customer: {
                                                  name: customer.name,
                                                  address: customer.address,
                                                  tel: customer.tel,
                                                },
                                              }); 
                                              document.getElementById("product-tab").click();
                                              setTimeout(() => {
                                                document.getElementById('searchProduct').focus();
                                              }, 1000);
                                            }}
                                            disabled={isDisableInput}
                                          />
                                        </div>
                                      </td>
                                      <td>{customer.name}</td>
                                      <td>{customer.address}</td>
                                      <td>{customer.tel}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                          <nav aria-label="Page navigation example">
                            <ReactPaginate
                              previousLabel={"ก่อนหน้า"}
                              nextLabel={"ต่อไป"}
                              pageCount={customerTotal}
                              pageRangeDisplayed={5}
                              previousClassName={"page-item"}
                              marginPagesDisplayed={1}
                              nextClassName={"page-item"}
                              pageClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              pageLinkClassName={"page-link"}
                              onPageChange={(data) =>
                                fetchCustomer(data.selected, customerName)
                              }
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </nav>
                        </div>
              </div>
            </div> */}
            <div class="tab-pane fade show active" id="product" role="tabpanel" aria-labelledby="product-tab">
            <div className="row">
                        <div className="col-12">
                          {/* text input */}
                          <div className="form-group">
                            <label>ค้นหาสินค้า</label>
                            <SearchComponent
                              id="searchProduct"
                              value={searchProductValue}
                              onSearch={searchProductOnType}
                              placeholder="ชื่อสินค้า, รหัสบาร์โค้ด, ตำแหน่ง ..."
                              disabled={isDisableInput}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="table-responsive text-nowrap">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th className="text-center">เลือก</th>
                                  <th
                                    className="text-center"
                                  >
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  </th>
                                  <th>ชื่อสินค้า</th>
                                  {/* <th>ตำแหน่ง</th>
                                  <th className="text-right">จำนวนคงเหลือ</th>
                                  <th className="text-right">ราคา</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {products.map((product, idx) => {
                                  // const productPrice = product.product_prices.find(
                                  //   (value) =>
                                  //     value.customer_type_id === customerType
                                  // );
                                  return (
                                    <tr key={`select-product-${product.id}`}>
                                      <td className="text-center">
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={
                                              selectProducts.filter(
                                                (selectProduct) =>
                                                  selectProduct.id ===
                                                  product.id
                                              ).length
                                            }
                                            onChange={(e) =>
                                              selectProduct(e, product)
                                            }
                                            disabled={isDisableInput}
                                          />
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        <button
                                          type="button"
                                          className="btn btn-info"
                                          data-toggle="modal"
                                          data-target="#exampleModal"
                                          onClick={() =>
                                            setProductDetail({
                                              name: product.name,
                                              description: product.description,
                                              buyer: product.agent_embed || '-',
                                              barCode: product.barcode
                                                ? product.barcode.code
                                                : "-",
                                              partSpec: product.part_size,
                                              stockPosition: product.treasury
                                                ? product.treasury.place
                                                : "-",
                                              maximumStock:
                                                product.maximum_total,
                                              warningStock:
                                                product.warning_total,
                                              packTotal: product.per_pack_total,
                                              pTotal: product.p,
                                              vat: +product.has_vat,
                                              images: product.images,
                                            })
                                          }
                                        >
                                          <i className="fa fa-eye mr-1"></i>
                                        </button>
                                      </td>
                                      <td>{product.name}</td>
                                      {/* <td>
                                        {product.treasury
                                          ? product.treasury.place
                                          : "-"}
                                      </td>
                                      <td className="text-right">
                                        {product.current_stock}
                                      </td>
                                      <td className="text-right">
                                        {currency(
                                          productPrice ? productPrice.price : 0,
                                          { separator: ",", symbol: "" }
                                        ).format()}
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-end">
                          <nav aria-label="Page navigation example">
                            <ReactPaginate
                              previousLabel={"ก่อนหน้า"}
                              nextLabel={"ต่อไป"}
                              pageCount={productTotal}
                              pageRangeDisplayed={5}
                              previousClassName={"page-item"}
                              marginPagesDisplayed={1}
                              nextClassName={"page-item"}
                              pageClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              pageLinkClassName={"page-link"}
                              onPageChange={handleProductPageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </nav>
                        </div>
                      </div>
            </div>
            <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
            <div className="row">
                        <div className="col-12">
                          <label>ค้นหารายการขาย</label>
                          <div className="row">
                            <div className="col-10">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={searchOrder}
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      fetchOrders(1, searchOrder);
                                    }
                                  }}
                                  onChange={(e) =>
                                    setSearchOrder(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-2">
                              <button
                                type="button"
                                className="btn btn-primary btn-block"
                                onClick={() => fetchOrders(1, searchOrder)}
                              >
                                <i className="fas fa-search mr-1"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="table-responsive text-nowrap">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>เลือก</th>
                                  <th>สถานะ</th>
                                  <th>รหัสการขาย</th>
                                  <th>วันที่บันทึกข้อมูล</th>
                                  <th>ชื่อร้าน</th>
                                  <th>ที่อยู่</th>
                                  <th colspan="2">เบอร์โทร</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orders.map((order) => {
                                  return (
                                    <tr key={order.id}>
                                      <td>
                                        <div className="form-check">
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={selectOrder.id === order.id}
                                            value={order.id}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                setSelectOrder(order);
                                                confirmOrderDetailBtn.current.click();
                                              } else {
                                                clearAllState();
                                              }
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td>{order.status !== ORDER_STATUS.DRAFT ? <span class="badge badge-success">จบรายการ</span> : <span class="badge badge-warning">กำลังดำเนินการ</span>}</td>
                                      <td>{order.order_code}</td>
                                      <td>{order.order_date}</td>
                                      <td>{order.customer_name}</td>
                                      <td>{order.customer_address}</td>
                                      <td>{order.customer_tel}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-12 d-flex justify-content-end">
                          <nav aria-label="Page navigation example">
                            <ReactPaginate
                              previousLabel={"ก่อนหน้า"}
                              nextLabel={"ต่อไป"}
                              pageCount={orderTotal}
                              pageRangeDisplayed={5}
                              previousClassName={"page-item"}
                              marginPagesDisplayed={1}
                              nextClassName={"page-item"}
                              pageClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextLinkClassName={"page-link"}
                              pageLinkClassName={"page-link"}
                              onPageChange={(data) =>
                                fetchOrders(data.selected, searchOrder)
                              }
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"}
                            />
                          </nav>
                        </div>
                      </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button
    type="button"
    ref={confirmOrderDetailBtn}
    className="d-none"
    data-toggle="modal"
    data-target="#confirmModal"
    data-keyboard="false"
    data-backdrop="static"
  />
  <ProductDetailModal {...productDetail} />
      <OrderDetailModal
        {...orderDetail}
        submit={submit}
        id={"orderDetailModal"}
      />
      <OrderDetailModal
        {...orderDetail}
        submit={submitEndTransaction}
        id={"orderDetailEndTransactionModal"}
      />
      <ConfirmModal
        title={"แจ้งเตือน"}
        description={"หากคุณกดตกลงข้อมูลเก่าจะถูกแทนที่ข้อมูลเดิม"}
        submit={submitOrderDetail}
        cancel={() => {
          setSelectOrder({
            id: "",
          });
        }}
      />
      <ReactToPrint
        trigger={() => (
          <button
            ref={printOrderDetailBtn}
            type="button"
            className="d-none"
          ></button>
        )}
        content={() => componentPrint.current}
      />
      <div className="d-none">
        <OrderPrint
          ref={componentPrint}
          products={selectProducts}
          owner={ownerName}
          customerType={orderDetail.customer_type}
          paymentChannel={orderDetail.payment_channel.display}
          customer={{
            name: orderDetail.customer.name,
            address: orderDetail.customer.address,
            tel: orderDetail.customer.tel,
          }}
        />
      </div>
</>
)
}

const mapStateToProps = (state) => ({
  owner: `${state.user.name} ${state.user.lastname}`,
});

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);