import React, { useState, useCallback, useEffect, useRef } from "react";
import ConfirmModal from "../modals/ConfirmModal";
import axios from "../../config/service";
import ReactPaginate from "react-paginate";
import LoadingComponent from "../other/loading";
import { setTitleHeader } from "../../actions/menus";
import { connect } from "react-redux";
import * as Yup from "yup";
import moment from "moment";
import "moment/locale/th";
import { useAlert } from "react-alert";
import DatePicker, { registerLocale } from "react-datepicker";
import { th } from "date-fns/esm/locale";
import customHeader from "../calendar/CustomHeaderCalendar";
import currency from "currency.js";

import "react-datepicker/dist/react-datepicker.css";
moment().locale("th");
registerLocale("th", th);

const schemaName = Yup.object().shape({
  name: Yup.string().trim().required("กรุณากรอกชื่อรายการจ่าย"),
});

const schemaChooseTotal = Yup.object().shape({
  choose_total: Yup.number("กรุณาเฉพาะตัวเลข")
    .positive("กรุณากรอกเลขจำนวนเต็ม")
    .typeError("กรุณาเฉพาะตัวเลข")
    .integer("กรุณาเฉพาะตัวเลข")
    .required("กรุณากรอกจำนวน"),
});

const schemaCost = Yup.object().shape({
  cost: Yup.string()
    .trim()
    .matches(/^\d+(\.\d{1,2})?$/, "กรุณากรอกเฉพาะตัวเลขและทศนิยมไม่เกิน 2 หลัก")
    .required("กรุณากรอกราคา"),
});

const Index = ({ setTitleHeader, owner }) => {
  const alert = useAlert();
  const [isLoading, setLoading] = useState(true);
  const [isLoadingButton, setLoadingButton] = useState(false);
  const [expenseLists, setExpenseList] = useState([]);
  const [dealDate, setDealDate] = useState(new Date());
  const [canSubmit, setCanSubmit] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [historyTotal, setHistoryTotal] = useState(0);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [importDate, setImportDate] = useState(new Date());
  const [expenseTotal, setExpenseTotal] = useState(0);
  const [expenseHistory, setExpenseHistory] = useState([]);
  const [historyExpense, setHistoryProducts] = useState([]);
  const isMounted = useRef(true);

  const fetchExpense = useCallback(
    async (page = 1, date = null) => {
      setLoading(true);
      try {
        const expenseAPI = await axios.get(
          `expenses/${date ? `?page=${page}&date=${date}` : `?page=${page}`}`
        );
        const {
          data: {
            expenses: { last_page, data: expenses },
          },
        } = expenseAPI;
        setExpenseTotal(last_page);
        setExpenseHistory(expenses);
        setLoading(false);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetchExpense();
    setTitleHeader({
      title: "รายจ่ายอื่นๆ",
      href: "/expense",
    });
    return () => {
      isMounted.current = false;
    };
  }, [fetchExpense, setTitleHeader]);

  const handleTotalChange = (event, idx, key) => {
    const value = event.target.value;
    let getExpenseList = [...expenseLists];
    let schemaInstance = schemaName;
    if (key === "cost") {
      schemaInstance = schemaCost;
    } else if (key === "choose_total") {
      schemaInstance = schemaChooseTotal;
    }
    schemaInstance
      .validate({
        [key]: value,
      })
      .then(() => {
        getExpenseList[idx][key] = value;
        getExpenseList[idx][`${key}Error`] = false;
        getExpenseList[idx][`${key}ErrorMessage`] = "";
        setExpenseList(getExpenseList);
      })
      .catch((error) => {
        getExpenseList[idx][key] = value;
        getExpenseList[idx][`${key}Error`] = true;
        getExpenseList[idx][`${key}ErrorMessage`] = error.message;
        setExpenseList(getExpenseList);
      });
  };

  const submitForCheckError = useCallback(() => {
    let isError = false;
    for (const product of expenseLists) {
      if (product.choose_totalError || product.costError || product.nameError) {
        isError = true;
        break;
      }
    }
    setCanSubmit(!isError);
  }, [expenseLists]);

  const submit = async () => {
    try {
      setLoadingButton(true);
      setLoading(true);
      const expenseFormat = expenseLists.map((expense) => {
        return {
          name: expense.name,
          amount: expense.choose_total,
          price: expense.cost,
        };
      });
      await axios.post("expenses", {
        expense_date: moment(dealDate).format("YYYY-MM-DD 00:00:00"),
        expenses: expenseFormat,
      });
      setLoading(false);
      setLoadingButton(false);
      alert.success("เพิ่มข้อมูลสำเร็จ.");
      setExpenseList([]);
      fetchExpense();
    } catch (error) {
      setLoading(false);
      setLoadingButton(false);
      console.log(error);
    }
  };

  const searchByDate = useCallback(
    (value) => {
      setDateValue(value);
      fetchExpense(1, moment(value).format("YYYY-MM-DD"));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePageClickWarehouse = useCallback(
    async (data) => {
      fetchExpense(
        data.selected + 1,
        dateValue ? moment(dateValue).format("YYYY-MM-DD") : null
      );
    },
    [dateValue, fetchExpense]
  );

  const handlePageClickHistory = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const historyAPI = await axios.get(
          `products/refunds/${moment(importDate).format(
            "YYYY-MM-DD"
          )}/refund_date?page=${data.selected + 1}`
        );
        const {
          data: {
            refunds: { last_page, data: history },
          },
        } = historyAPI;
        setHistoryProducts(history);
        setHistoryTotal(last_page);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
      }
    },
    [alert, importDate]
  );

  return (
    <>
      <LoadingComponent isLoading={isLoading} />
      <div className="row">
        <div className="col-12">
          <label for="exampleInputEmail1">วันที่ทำรายการ</label>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <DatePicker
                  placeholderText={moment(dealDate)
                    .add(543, "years")
                    .format("D MMMM YYYY ")}
                  className="form-control"
                  locale="th"
                  dateFormat="dd/MM/yyyy"
                  autoComplete="off"
                  onChange={(value) => setDealDate(value)}
                  renderCustomHeader={(props) => customHeader({ ...props })}
                />
              </div>
            </div>
            <div className="col-6 text-md-right">
              <label>ผู้ทำรายงาน</label> : {owner}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card card-warning">
            <div className="card-header">
              <h3 className="card-title">สินค้าที่เลือก</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() =>
                      setExpenseList([
                        ...expenseLists,
                        {
                          name: "",
                          nameError: true,
                          nameErrorMessage: "กรุณากรอกชื่อรายการจ่าย",
                          choose_total: 1,
                          cost: 0,
                          choose_totalError: false,
                          choose_totalErrorMessage: "",
                          costError: false,
                          costErrorMessage: "",
                        },
                      ])
                    }
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                    เพิ่มรายการรายจ่ายอื่นๆ
                  </button>
                </div>
                <div className="col-12 pt-3">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>รายการ</th>
                          <th className="text-right">จำนวน(ชิ้น)</th>
                          <th className="text-right">ราคา</th>
                          <th className="text-right">รวม</th>
                          <th className="text-center">ลบ</th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseLists.length > 0 ? (
                          expenseLists.map((expense, idx) => {
                            return (
                              <tr key={`selected-product-${idx}`}>
                                <td>{idx + 1}</td>
                                <td>
                                  <input
                                    className="form-control text-right"
                                    type="text"
                                    placeholder="รายการ"
                                    value={expense.name}
                                    onChange={(e) =>
                                      handleTotalChange(e, idx, "name")
                                    }
                                  />
                                  {expense.nameError && (
                                    <span className="text-danger">
                                      {expense.nameErrorMessage}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  <input
                                    className="form-control text-right"
                                    type="number"
                                    placeholder="จำนวน"
                                    value={expense.choose_total}
                                    onChange={(e) =>
                                      handleTotalChange(e, idx, "choose_total")
                                    }
                                  />
                                  {expense.choose_totalError && (
                                    <span className="text-danger">
                                      {expense.choose_totalErrorMessage}
                                    </span>
                                  )}
                                </td>
                                <td className="text-right">
                                  <input
                                    className="form-control text-right"
                                    type="number"
                                    placeholder="ราคา"
                                    value={expense.cost}
                                    onChange={(e) =>
                                      handleTotalChange(e, idx, "cost")
                                    }
                                  />
                                  {expense.costError && (
                                    <span className="text-danger">
                                      {expense.costErrorMessage}
                                    </span>
                                  )}
                                </td>
                                <td>
                                  {currency(
                                    +expense.choose_total * +expense.cost,
                                    { separator: ",", symbol: "" }
                                  ).format()}
                                </td>
                                <td className="text-center">
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger"
                                    onClick={() =>
                                      setExpenseList(
                                        expenseLists.filter(
                                          (_, idxValue) => idxValue !== idx
                                        )
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td className="text-center" colSpan="6">
                              ยังไม่มีรายจ่ายอื่นๆที่เลือก
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
            <div className="card-footer">
              {expenseLists.length !== 0 && (
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  data-toggle={canSubmit ? "modal" : ""}
                  data-target={canSubmit ? "#confirmModal" : ""}
                  onClick={() => submitForCheckError()}
                  disabled={isLoadingButton}
                >
                  <i className="fas fa-save mr-1"></i>
                  บันทึกรายการ
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">ประวัติซื้อรายจ่ายอื่นๆ</h3>
            </div>
            {/* /.card-header */}
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12">
                  {/* Search form */}
                  <DatePicker
                    name="import_date"
                    placeholderText={moment(dateValue)
                      .add(543, "years")
                      .format("D MMMM YYYY ")}
                    className="form-control w-100"
                    locale="th"
                    dateFormat="dd/MM/yyyy"
                    autoComplete="off"
                    onChange={(value) => searchByDate(value)}
                    renderCustomHeader={(props) => customHeader({ ...props })}
                  />
                </div>
                <div className="col-12">
                  <div className="table-responsive text-nowrap mt-3">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th className="text-center">
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          </th>
                          <th className="text-center">วันที่ทำรายการ</th>
                          <th className="text-center">
                            ยอดรวมจำนวนสิ่งของรายจ่ายอื่นๆทั้งหมด
                          </th>
                          <th className="text-center">
                            ยอดรวมราคาสิ่งของรายจ่ายอื่นๆระบบทั้งหมด
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {expenseHistory.map((expense) => {
                          return (
                            <tr key={expense.id}>
                              <td className="text-center">
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  data-toggle="modal"
                                  data-target="#historyModal"
                                  onClick={() => {
                                    setHistoryProducts(expense.history.data);
                                    setHistoryTotal(expense.history.last_page);
                                    setImportDate(expense.expense_date);
                                    setDateSelected(expense.expense_date);
                                  }}
                                >
                                  <i className="fa fa-eye mr-1"></i>
                                  ดูรายละเอียด
                                </button>
                              </td>
                              <td className="text-center">
                                {moment(expense.expense_date).add(543, 'years').format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="text-right">
                                {expense.amount_total}
                              </td>
                              <td className="text-right">
                                {expense.price_total}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="row mt-3 w-100">
                  <div className="col-12 d-flex justify-content-end">
                    <nav aria-label="Page navigation example">
                      <ReactPaginate
                        previousLabel={"ก่อนหน้า"}
                        nextLabel={"ต่อไป"}
                        pageCount={expenseTotal}
                        pageRangeDisplayed={5}
                        previousClassName={"page-item"}
                        marginPagesDisplayed={1}
                        nextClassName={"page-item"}
                        pageClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextLinkClassName={"page-link"}
                        pageLinkClassName={"page-link"}
                        onPageChange={handlePageClickWarehouse}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </div>
        </div>
      </div>
      <ConfirmModal
        title={"แจ้งเตือน"}
        description={"คุณต้องการยืนยันซื้อรายการจ่าอื่นๆหรือไม่"}
        submit={() => submit()}
        cancel={() => {}}
      />
      <div
        class="modal fade"
        id="historyModal"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                ประวัติรายจ่ายอื่นๆ วันที่{" "}
                {dateSelected ? moment(dateSelected).add(543, 'years').format("DD/MM/YYYY") : "-"}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>ผู้ทำรายงาน</th>
                          <th>รายการ</th>
                          <th>จำนวน</th>
                          <th className="text-right">ราคา</th>
                          <th className="text-right">รวม</th>
                        </tr>
                      </thead>
                      <tbody>
                        {historyExpense.map((expense, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{idx + 1}</td>
                              <td className="text-center">
                                {expense.created_by
                                  ? `${expense.created_by.name} ${expense.created_by.lastname}`
                                  : ``}
                              </td>
                              <td
                                className="td-word-break"
                                style={{ maxWidth: 100 }}
                              >
                                <p>{expense.name}</p>
                              </td>
                              <td className="text-center">
                                {currency(expense.amount, {
                                  separator: ",",
                                  symbol: "",
                                }).format()}
                              </td>
                              <td className="text-right">
                                {currency(expense.price, {
                                  separator: ",",
                                  symbol: "",
                                }).format()}
                              </td>
                              <td className="text-right">
                                {currency(+expense.amount * +expense.price, {
                                  separator: ",",
                                  symbol: "",
                                }).format()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <ReactPaginate
                    previousLabel={"ก่อนหน้า"}
                    nextLabel={"ต่อไป"}
                    pageCount={historyTotal}
                    pageRangeDisplayed={5}
                    previousClassName={"page-item"}
                    marginPagesDisplayed={1}
                    nextClassName={"page-item"}
                    pageClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    pageLinkClassName={"page-link"}
                    onPageChange={handlePageClickHistory}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  owner: `${state.user.name} ${state.user.lastname}`,
});

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
