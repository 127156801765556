import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import UserListsComponent from "./components/users";
import UserAddComponent from "./components/users/add";
import UserEditComponent from "./components/users/edit";
import ProductListsComponent from "./components/products";
import ProductAddComponent from "./components/products/add";
import ProductEditComponent from "./components/products/edit";
import TreasuryComponent from "./components/treasury";
import PricingComponent from "./components/pricing";
import WarehouseComponent from "./components/warehouse";
import IndexComponent from "./components";
import HistoryPricing from "./components/pricing/history";
import HistoryWarehouse from "./components/warehouse/history";
import UserRoute from "./components/router/UserRoute";
import GuestRoute from "./components/router/GuestRoute";
import PrintLabelComponent from "./components/printlabel";
import CustomerListsComponent from "./components/customer/index";
import CustomerAddComponent from "./components/customer/add";
import CustomerEditComponent from "./components/customer/edit";
import DealComponent from "./components/deal/index";
import BoughtBackComponent from "./components/boughtback/index";
import ExpenseComponent from "./components/expense/index";
import RefundStockComponent from './components/refund-stock/index';

class App extends Component {
  render() {
    return (
      <>
        <UserRoute exact path="/" component={IndexComponent} />
        <UserRoute
          exact
          path="/customers"
          component={CustomerListsComponent}
          permissionAccess="manage-customers"
        />
        <UserRoute
          exact
          path="/customers/add"
          component={CustomerAddComponent}
          permissionAccess="manage-customers"
        />
        <UserRoute
          exact
          path="/customers/edit/:id"
          component={CustomerEditComponent}
          permissionAccess="manage-customers"
        />
        <UserRoute
          exact
          path="/users"
          component={UserListsComponent}
          permissionAccess="manage-users"
        />
        <UserRoute
          path="/users/add"
          component={UserAddComponent}
          permissionAccess="manage-users"
        />
        <UserRoute
          path="/users/edit/:id"
          component={UserEditComponent}
          permissionAccess="manage-users"
        />
        <UserRoute
          exact
          path="/products"
          component={ProductListsComponent}
          permissionAccess="manage-products"
        />
        <UserRoute
          path="/products/add"
          component={ProductAddComponent}
          permissionAccess="manage-products"
        />
        <UserRoute
          path="/products/edit/:id"
          component={ProductEditComponent}
          permissionAccess="manage-products"
        />
        <UserRoute path="/treasury" component={TreasuryComponent} permissionAccess="manage-treasury" />
        <UserRoute
          exact
          path="/pricing"
          component={PricingComponent}
          permissionAccess="manage-price"
        />
        <UserRoute
          exact
          path="/warehouse"
          component={WarehouseComponent}
          permissionAccess="manage-warehouse"
        />
        <UserRoute
          path="/pricing/history"
          component={HistoryPricing}
          permissionAccess="manage-price"
        />
        <UserRoute
          path="/warehouse/history"
          component={HistoryWarehouse}
          permissionAccess="manage-warehouse"
        />
        <UserRoute path="/printlabel" component={PrintLabelComponent} />
        <UserRoute path="/deal" component={DealComponent} />
        <UserRoute path="/boughtback" component={BoughtBackComponent} />
        <UserRoute path="/expense" component={ExpenseComponent} />
        <UserRoute 
          path="/refund-stock" 
          component={RefundStockComponent}
          permissionAccess="manage-stock-split"
        />
        <GuestRoute path="/login" />
      </>
    );
  }
}

export default withRouter(App);
