import React from "react";
import { Route, Redirect } from "react-router-dom";
import UnAuthorization from "./UnAuthorization";

const GuestRoute = ({ ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      !localStorage.getItem("token") ? (
        <UnAuthorization />
      ) : (
        <Redirect to="/users" />
      )
    }
  />
);
export default GuestRoute;
