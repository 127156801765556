import React from "react";
import currency from "currency.js";

const OrderDetailModal = ({
  customer_type = {
    value: "",
    display: "",
  },
  payment_channel = {
    value: "",
    display: "",
  },
  customer = { name: "", address: "", tel: "" },
  date = {
    value: "",
    display: "",
  },
  staff_name = "",
  products = [],
  id,
  submit,
}) => {
  return (
    <>
      <div className="modal fade" id={id} role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ข้อมูลออเดอร์</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row mt-3">
                <div className="col-sm-12 col-md-6">
                  <div className="card card-info">
                    <div className="card-header">
                      <h3 className="card-title">ข้อมูลลูกค้า</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <label>ประเภทลูกค้า</label>
                      <p>{customer_type.display}</p>
                      <label>ช่องทางการชำระเงิน</label>
                      <p>{payment_channel.display}</p>
                      <label>ชื่อลูกค้า</label>
                      <p>{customer.name}</p>
                      <label>ที่อยู่</label>
                      <p>{customer.address}</p>
                      <label>เบอร์โทรศัพท์</label>
                      <p>{customer.tel}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="card card-success">
                    <div className="card-header">
                      <h3 className="card-title">ข้อมูลออเดอร์</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <label>วันที่ทำรายการ</label>
                      <p>{date.display}</p>
                      <label>ผู้ทำรายงาน</label>
                      <p>{staff_name}</p>
                      <div className="row">
                        <div className="col-12">
                          <label>รายการสินค้า</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">ชื่อสินค้า</div>
                        <div className="col-2">จำนวน</div>
                        <div className="col-2">ราคาต่อหน่วย</div>
                        <div className="col-3">ราคารวม</div>
                      </div>
                      <hr />
                      {products.map((product) => {
                        const productPrice = product.product_prices.find(
                          (value) =>
                            value.customer_type_id === customer_type.value
                        );
                        return (
                          <div key={product.id} className="row">
                            <div className="col-5">{product.name}</div>
                            <div className="col-2">
                              x {product.choose_total}
                            </div>
                            <div className="col-2">
                              {productPrice
                                ? currency(productPrice.price, {
                                    separator: ",",
                                    symbol: "",
                                  }).format()
                                : 0}
                            </div>
                            <div className="col-3">
                              {productPrice
                                ? currency(
                                    +productPrice.price * +product.choose_total,
                                    {
                                      separator: ",",
                                      symbol: "",
                                    }
                                  ).format()
                                : 0}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => submit()}
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetailModal;
