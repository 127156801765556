import React from "react";
import Carousel from "../carousel/Carousel";
var Barcode = require("react-barcode");

const ProductDetailModal = ({
  name,
  description,
  buyer,
  barCode,
  partSpec = "-",
  stockPosition = "-",
  maximumStock,
  warningStock,
  packTotal,
  pTotal,
  vat,
  images = [],
}) => {
  return (
    <>
      <div
        className="modal fade"
        id="exampleModal"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">ข้อมูลสินค้า</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row mt-3">
                <div className="col-sm-12 col-md-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">ชื่อสินค้า</label>
                        <input
                          disabled
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="กรุณากรอกชื่อสินค้า"
                          value={name}
                        />
                      </div>
                      <div className="form-group">
                        <label>รายละเอียดสินค้า</label>
                        <textarea
                          disabled
                          name="description"
                          className="form-control"
                          rows="3"
                          placeholder="กรุณากรอกรายละเอียดสินค้า"
                          value={description}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group d-flex flex-column">
                        <label htmlFor="exampleInputEmail1">ซื้อมาจาก</label>
                        <input
                          disabled
                          name="barcode"
                          type="text"
                          className="form-control"
                          placeholder="ซื้อมาจาก"
                          value={buyer}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group d-flex flex-column">
                        <label htmlFor="exampleInputEmail1">บาร์โค้ด</label>
                        <input
                          disabled
                          name="barcode"
                          type="text"
                          className="form-control"
                          placeholder="บาร์โค้ดเดิมของสินค้า"
                          value={barCode}
                        />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      {barCode && <Barcode value={barCode} />}
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <label htmlFor="exampleInputEmail1">
                        พาทเบอร์อะไหล่ (อะไหล่ของแท้)
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <label htmlFor="exampleInputEmail1">
                        ตำแหน่งสต็อคสินค้า
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <div className="form-group">
                        <input
                          disabled
                          name="part_size"
                          type="text"
                          className="form-control"
                          placeholder="พาทเบอร์อะไหล่ (อะไหล่ของแท้)"
                          value={partSpec}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <div className="form-group d-flex flex-column">
                        <input
                          disabled
                          name="maximum_total"
                          type="text"
                          className="form-control text-right"
                          placeholder="ตำแหน่งสต็อคสินค้า"
                          value={stockPosition}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          ยอดสูงสุดในการสต็อค
                        </label>
                        <input
                          disabled
                          name="maximum_total"
                          type="text"
                          className="form-control text-right"
                          placeholder="ยอดสูงสุดในการสต็อค"
                          value={maximumStock}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">
                          แจ้งเตือนสั่งซื้อ
                        </label>
                        <input
                          disabled
                          name="warning_total"
                          type="text"
                          className="form-control text-right"
                          placeholder="แจ้งเตือนสั่งซื้อ"
                          value={warningStock}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">จำนวนต่อแพ็ค</label>
                        <input
                          disabled
                          name="per_pack_total"
                          type="text"
                          className="form-control text-right"
                          placeholder="จำนวนต่อแพ็ค"
                          value={packTotal}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6 pt-3">
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">P</label>
                        <input
                          disabled
                          name="p"
                          type="text"
                          className="form-control text-right"
                          placeholder="p"
                          value={pTotal}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>ประเภท Vat ของสินค้า</label>
                        <div className="form-check">
                          <input
                            disabled
                            className="form-check-input"
                            type="radio"
                            name="has_vat"
                            checked={vat === 1}
                          />
                          <label className="form-check-label">มี vat</label>
                        </div>
                        <div className="form-check">
                          <input
                            disabled
                            className="form-check-input"
                            type="radio"
                            name="has_vat"
                            checked={vat === 0}
                          />
                          <label className="form-check-label">ไม่มี vat</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <Carousel images={images ? images : []} />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetailModal;
