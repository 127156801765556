import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "../../config/service";
import currency from "currency.js";
import { setTitleHeader } from "../../actions/menus";
import { connect } from "react-redux";
import SearchComponent from "../input/search";
import LoadingComponent from "../other/loading";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import moment from "moment";

const validationSchema = Yup.object().shape({
  total: Yup.number("กรุณากรอกเฉพาะตัวเลข")
    .typeError("กรุณากรอกเฉพาะตัวเลข")
    .integer("กรุณากรอกเฉพาะตัวเลข")
    .min(1, "กรุณากรอกจำนวนอย่างน้อยหนึ่งจำนวน")
    .required("กรุณากรอกจำนวน"),
});

const Warehouse = ({ setTitleHeader, owner }) => {
  const alert = useAlert();
  const [product, setProduct] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [packTotal, setPackTotal] = useState(0)
  const modalBtn = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    setTitleHeader({
      title: "จัดการแตกสินค้า",
      href: "/refund-stocks",
    });
    return () => {
      isMounted.current = false;
    };
  }, [setTitleHeader]);

  const formik = useFormik({
    initialValues: {
      total: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPackTotal(+values.total)
      modalBtn.current.click();
    },
  });

  const searchOnType = useCallback((value) => {
    setIsSuccess(false);
    const fetchProductsByBarcode = async (value) => {
      try {
        setSearchText(value)
        setIsSearch(false)
        const productAPI = await axios.get(`refund-stocks/products?value=${value}`);
        const {
          data: { product },
        } = productAPI;
        if (product) {
          setNotFound(false);
          setProduct({
            id: product.id,
            sub_product_id: product.sub_product_id,
            productParentName: product.productParentName,
            productParentPerPackTotal: product.productParentPerPackTotal,
            productChildName: product.productChildName,
            last_cost_price: product.last_cost_price ? product.last_cost_price.price : null,
            last_cost_price_date: product.last_cost_price ? product.last_cost_price.date : null,
            productParentCurrentStock: product.productParentCurrentStock
          });
        } else {
          setProduct(null);
          setNotFound(true);
        }
        setLoading(false);
        setIsSearch(true);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setIsSearch(true);
        setLoading(false);
        setProduct(null);
        setNotFound(true);
      }
    };
    setLoading(true);
    fetchProductsByBarcode(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickCreateStockSplit = useCallback(async () => {
    if (loading || packTotal <= 0) {
      return;
    }
    setBtnLoading(true);
    setLoading(true);
    try {
      await axios.post(`refund-stocks`, {
        product_id: product.id,
        pack_total: packTotal
      });
      setLoading(false);
      setBtnLoading(false);
      setIsSuccess(true);
      setPackTotal(0)
      setProduct(null);
      setSearchText('')
      setIsSearch(false)
      setNotFound(false);
      window.scrollTo(0, 0);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert.error("มีข้อผิดพลาดเกิดขึ้น.");
      }
      setLoading(false);
      setBtnLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, packTotal]);

  return (
    <>
      <LoadingComponent isLoading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">จัดการแตกสินค้า</h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <div className="card-body">
              <div className="row pb-3">
                {isSuccess && (
                  <div className="col-12">
                    <div className="row">
                      <div className="col-6">
                        <div className="alert alert-success" role="alert">
                          บันทึกข้อมูลสำเร็จ.
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>บาร์โคด</label>
                        <SearchComponent value={searchText} onSearch={searchOnType} />
                      </div>
                    </div>
                    {isSearch &&
                      (notFound || !product ? (
                        <div className="alert alert-danger" role="alert">
                          ไม่พบสินค้า.
                        </div>
                      ) : (
                        <div className="col-12">
                          <form onSubmit={formik.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label>ชื่อสินค้าหลัก</label>
                                  <input
                                      type="text"
                                      className="form-control"
                                      disabled
                                      value={product.productParentName}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                              <div className="form-group">
                                  <label>ชื่อสินค้ารอง</label>
                                  <input
                                      type="text"
                                      className="form-control"
                                      disabled
                                      value={product.productChildName}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  <label>จำนวนนำเข้า</label>
                                  <input
                                    type="number"
                                    name="total"
                                    className="form-control text-right"
                                    placeholder="กรอกจำนวนแพ็ค"
                                    onChange={(e) => {
                                      const {
                                        target: { value },
                                      } = e;
                                      formik.setFieldValue("total", value);
                                    }}
                                    value={formik.values.total}
                                  />
                                  {(() => {
                                    if(+product.productParentCurrentStock < (+formik.values.total * +product.productParentPerPackTotal)) {
                                      return (
                                        <span className="text-danger">จำนวนแพ็คในตอนนี้เหลืออยู่ {product.productParentCurrentStock}</span>
                                      )
                                    } else if(formik.errors.total) {
                                      return (
                                        <span className="text-danger">
                                          {formik.errors.total}
                                        </span>
                                      )
                                    }
                                  })()}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 pt-2">
                                <div className="form-group">
                                  <label>จำนวนชิ้นทั้งหมด</label>
                                  <input
                                    type="text"
                                    className="form-control text-right"
                                    disabled
                                    value={+formik.values.total * +product.productParentPerPackTotal}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 pt-2">
                                <div className="form-group">
                                  <label>ต้นทุนล่าสุด ({moment(product.last_cost_price_date).add(543, "years").format('DD/MM/YY')})</label>
                                  <input
                                    type="text"
                                    className="form-control text-right"
                                    disabled
                                    value={currency(product.last_cost_price, 
                                      {
                                        separator: ",",
                                        symbol: "",
                                      }).format()
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <label>ผู้ทำรายงาน</label> : {owner}
                              </div>
                              <div className="col-12 pt-3">
                                {
                                  btnLoading ? (
                                    <button type="submit" className="btn btn-primary">
                                      <i className="fas fa-spinner fa-spin"></i>
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                    >
                                      <i className="fas fa-save"></i> ยืนยัน
                                    </button>
                                  )
                                }
                              </div>
                              <button
                                type="button"
                                ref={modalBtn}
                                className="d-none"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                data-keyboard="false"
                                data-backdrop="static"
                              />
                            </div>
                          </form>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                ยืนยันการแตกสินค้า
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">คุณต้องการแตกสินค้าไปยังสินค้ารองหรือไม่ ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onClickCreateStockSplit()}
                data-dismiss="modal"
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  owner: `${state.user.name} ${state.user.lastname}`,
});

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);
