import React, { useState, useEffect, useRef } from "react";

const Search = (props) => {
  const [value, setValue] = useState(props.value ? props.value: '');
  const [needSearch, setNeedSearch] = useState(false);
  const timeoutRef = useRef(null);

  const getPropsOnSearch = props.onSearch;

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (value || needSearch) {
        getPropsOnSearch(value);
      }
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if(needSearch) {
        setNeedSearch(false)
        getPropsOnSearch(value);
      }
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needSearch]);

  return (
    <div className="input-group md-form form-sm form-1 pl-0">
      <div className="input-group-prepend">
        <span className="input-group-text purple lighten-3">
          <i className="fas fa-search text-white" aria-hidden="true" />
        </span>
      </div>
      <input
        id={props.id || 'searchComponent'}
        value={value || ''}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === "Backspace") {
            setNeedSearch(true);
            setValue(e.target.value);
          }
        }}
        className="form-control my-0 py-1"
        type="text"
        placeholder={props.placeholder || "ค้นหา"}
        aria-label="Search"
        disabled={props.disabled || false}
      />
    </div>
  );
};

export default Search;
