import React, { useState, useEffect, useRef, useCallback } from "react";
import { useFormik } from "formik";
import Yup from "../../utils/yup";
import axios from "../../config/service";
import Autocomplete from "react-autocomplete";
import {
  matchStateToTermBarcode,
  matchStateToTermAgent,
  matchStateToTermTreasury,
} from "../../utils/autocomplete";
import { withRouter, Link } from "react-router-dom";
import LoadingComponent from "../other/loading";
import { connect } from "react-redux";
import { setTitleHeader } from "../../actions/menus";
import { useAlert } from "react-alert";
import * as qs from 'query-string';
var Barcode = require("react-barcode");

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required("กรุณากรอกชื่อสินค้า"),
  description: Yup.string().trim().required("กรุณากรอกรายละเอียดสินค้า"),
  agent: Yup.string().trim().required("กรุณากรอกแหล่งที่ซื้อ"),
  barcode: Yup.string().trim().required("กรุณากรอกบาร์โค้ด"),
  maximum_total: Yup.number("กรุณาเฉพาะตัวเลข")
    .typeError("กรุณาเฉพาะตัวเลข")
    .integer("กรุณาเฉพาะตัวเลข")
    .required("กรุณากรอกยอดสูงสุดในการสต็อค"),
  warning_total: Yup.number("กรุณาเฉพาะตัวเลข")
    .typeError("กรุณาเฉพาะตัวเลข")
    .integer("กรุณาเฉพาะตัวเลข")
    .required("กรุณากรอกแจ้งเตือนสั่งซื้อ"),
  has_vat: Yup.string().required("กรุณาเลือกต้องการ Vat หรือไม่"),
  per_pack_total: Yup.string().required("กรุณากรอกจำนวนต่อแพ็ค"),
  p: Yup.string()
    .required("กรุณากรอกข้อมูล P")
    .max(8, "สามารถกรอกข้อมูลสูงสุดได้ 8 ตัว"),
});

const EditForm = ({
  location,
  match: {
    params: { id: productId },
  },
  history,
  setTitleHeader,
}) => {
  const alert = useAlert();
  const first_image_ref = useRef(null);
  const second_image_ref = useRef(null);

  const [productState, setProduct] = useState({
    name: "",
    description: "",
    agent: "",
    custom_barcode: "",
    generate_barcode: "",
    barcode: "",
    part_size: "",
    treasury: "",
    maximum_total: "",
    warning_total: "",
    has_vat: "",
    per_pack_total: "",
    p: "",
    sub_product_id: ""
  });
  const [first_image, setFirstImage] = useState();
  const [previewFirstImage, setPreviewFirstImage] = useState();
  const [second_image, setSecondImage] = useState();
  const [previewSecondImage, setPreviewSecondImage] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingFetch, setLoadingFetch] = useState(true);
  const [barcodeLists, setBarcodeLists] = useState([]);
  const [agentLists, setAgentLists] = useState([]);
  const [treasuryLists, setTreasuryLists] = useState([]);
  const [deletedImage, setDeleteImage] = useState([]);
  const [query, setQuery] = useState({})
  const [searchProduct, setSearchProduct] = useState('')
  const [productLists, setProductLists] = useState([]);
  const timeoutRef = useRef(null);
  const isMounted = useRef(true);

  const openFirstImageFileRef = () => {
    first_image_ref.current.click();
  };

  const openSecondImageFileRef = () => {
    second_image_ref.current.click();
  };

  const onSelectFile = (e, no) => {
    if (!e.target.files || e.target.files.length === 0) {
      switch (no) {
        case "first":
          setFirstImage(undefined);
          break;
        case "second":
          setSecondImage(undefined);
          break;
        default:
          break;
      }
      return;
    }

    let getProduct = productState;
    let getDeleteImage = deletedImage;
    const value = e.target.files[0];
    switch (no) {
      case "first":
        if (productState.first_image_id) {
          getDeleteImage[0] = productState.first_image_id;
          delete getProduct.first_image_id;
          setDeleteImage(getDeleteImage);
          setProduct(getProduct);
        }
        setFirstImage(value);
        break;
      case "second":
        if (productState.second_image_id) {
          getDeleteImage[1] = productState.second_image_id;
          delete getProduct.second_image_id;
          setDeleteImage(getDeleteImage);
          setProduct(getProduct);
        }
        setSecondImage(value);
        break;
      default:
        break;
    }
  };

  // set isMounted to false when we unmount the component
  useEffect(() => {
    const fetchProductAndBarcode = async () => {
      try {
        const [
          productAPI,
          barcodeAPI,
          agentAPI,
          treasuryAPI
        ] = await Promise.all([
          axios.get(`products/${productId}`),
          axios.get("barcodes"),
          axios.get("agents"),
          axios.get("treasury")
        ])
        const {
          data: { barcodes },
        } = barcodeAPI;
        setBarcodeLists(barcodes);
        const {
          data: { agents },
        } = agentAPI;
        setAgentLists(agents);
        const {
          data: { treasury: treasuryData },
        } = treasuryAPI;
        setTreasuryLists(treasuryData);
        const {
          data: {
            product: {
              id,
              name,
              description,
              agent: { name: agentName },
              part_size,
              treasury,
              maximum_total,
              warning_total,
              per_pack_total,
              has_vat,
              p,
              images,
              barcode,
              sub_product_id,
            },
          },
        } = productAPI;
        if(sub_product_id) {
          const subProduct = await axios.get(`products/${sub_product_id}`)
          setSearchProduct(subProduct.data.product.barcode.code)
        }
        setQuery(qs.parse(location.search))
        setProduct({
          id: id,
          name: name,
          description: description,
          agent: agentName,
          barcode: barcode ? barcode.code : '',
          part_size: part_size ? part_size : '',
          treasury: treasury ? treasury.place : "",
          maximum_total: maximum_total,
          warning_total: warning_total,
          per_pack_total: per_pack_total,
          p: p,
          has_vat: has_vat,
          custom_barcode: barcode ? barcode.code : '',
          first_image_id: images.length > 0 ? images[0].id : "",
          second_image_id: images.length === 2 ? images[1].id : "",
          sub_product_id: sub_product_id ? sub_product_id : ''
        });
        setPreviewFirstImage(images.length > 0 ? images[0].path.large : "");
        setPreviewSecondImage(images.length === 2 ? images[1].path.large : "");
        setLoadingFetch(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        console.log(error);
      }
    };
    fetchProductAndBarcode();
    setTitleHeader({
      title: "แก้ไขข้อมูลสินค้า",
      href: `/products/edit/${productId}`,
    });
    return () => {
      isMounted.current = false;
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, setTitleHeader]);

  // on change first image state
  useEffect(() => {
    if (!first_image) {
      setPreviewFirstImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(first_image);
    setPreviewFirstImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [first_image]);

  // on change second image state
  useEffect(() => {
    if (!second_image) {
      setPreviewSecondImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(second_image);
    setPreviewSecondImage(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [second_image]);

  const onSubmitData = useCallback(
    async (value, setFieldError, setSubmitting) => {
      if (isLoading) {
        return;
      }
      setLoading(true);
      let data = new FormData();

      data.append("name", value.name);
      data.append("description", value.description);
      data.append("agent", value.agent);
      data.append("barcode", value.barcode);
      data.append("part_size", value.part_size);
      data.append("treasury", value.treasury);
      data.append("warning_total", value.warning_total);
      data.append("maximum_total", value.maximum_total);
      data.append("per_pack_total", value.per_pack_total);
      data.append("p", value.p);
      data.append("has_vat", value.has_vat);
      data.append('sub_product_id', value.sub_product_id);
      if (first_image) {
        data.append("first_image", first_image);
      }
      if (second_image) {
        data.append("second_image", second_image);
      }

      for (let i = 0; i < deletedImage.length; i++) {
        if (deletedImage[i]) {
          data.append(`delete_image${i + 1}`, deletedImage[i]);
        }
      }

      await axios
        .post(`products/${productId}`, data, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then(() => {
          alert.success("แก้ไขข้อมูลสำเร็จ.");
          history.push(`/products?page=${query.page ? query.page : 1}&search=${query.search ? query.search : ''}`);
        })
        .catch((error) => {
          if (error.response.status === 422) {
            for (const [key, value] of Object.entries(error.response.data)) {
              setFieldError(key, value);
            }
          }
          setSubmitting(false);
          if (isMounted.current) {
            setLoading(false);
          }
        });
    },
    [isLoading, first_image, second_image, productId, deletedImage, alert, history, query.page, query.search]
  );

  const formik = useFormik({
    initialValues: productState,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setFieldError, setSubmitting }) => {
      await onSubmitData(values, setFieldError, setSubmitting);
    },
  });

  useEffect(() => {
    if (formik.isValid || !formik.isSubmitting) return;
    const keys = Object.keys(formik.errors);
    if (keys.length > 0 && formik.isSubmitting && !formik.isValidating) {
      const selector = `[name="${keys[0]}"]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        errorElement.focus();
      }
    }
  }, [formik.isSubmitting, formik.isValid, formik.errors, formik.isValidating]);

  const removeFirstImage = useCallback(
    (image_id) => {
      let getProduct = productState;
      delete getProduct.first_image_id;
      setDeleteImage([...deletedImage, image_id]);
      setPreviewFirstImage("");
      setProduct(getProduct);
    },
    [deletedImage, productState]
  );

  const removeSecondImage = useCallback(
    (image_id) => {
      let getProduct = productState;
      delete getProduct.second_image_id;
      setDeleteImage([...deletedImage, image_id]);
      setPreviewSecondImage("");
      setProduct(getProduct);
    },
    [deletedImage, productState]
  );

  useEffect(() => {
    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(async () => {
      if(searchProduct) {
        try {
          setLoading(true)
          const products = await axios.get(`products/auto-complete?search=${searchProduct}`);
          if(products.data.products && products.data.products.id !== productId) {
            setProductLists([
              {
                ...products.data.products
              }
            ])
            formik.setFieldValue('sub_product_id', products.data.products.id)
          } else {
            setProductLists([])
            formik.setFieldValue('sub_product_id', '')
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          if (error.response && error.response.status === 500) {
            alert.error("มีข้อผิดพลาดเกิดขึ้น.");
          }
          console.log(error);
        }
      } else {
        setProductLists([])
        formik.setFieldValue('sub_product_id', '')
      }
    }, 500);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProduct]);

  return (
    <>
      <LoadingComponent isLoading={isLoading || isLoadingFetch} />
      <div className="card card-info">
        <div className="card-header">
          <h3 className="card-title">ฟอร์มแก้ไขข้อมูลสินค้า</h3>
        </div>
        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
          <div className="card-body">
            <div className="row">
              <div className="col-12 preview-container">
                <div className="row">
                  <div className="col-sm-12 col-md-6 text-center">
                    <input
                      type="file"
                      name="first_image"
                      className="d-none"
                      onChange={(e) => onSelectFile(e, "first")}
                      ref={first_image_ref}
                    />
                    <img
                      src={
                        first_image || previewFirstImage
                          ? previewFirstImage
                          : `/images/image_default.jpg`
                      }
                      className="preview"
                      alt="pos_stock"
                      onClick={openFirstImageFileRef}
                    />
                    {productState.first_image_id && (
                      <button
                        type="button"
                        className="btn btn-outline-danger mt-3"
                        onClick={() =>
                          removeFirstImage(productState.first_image_id)
                        }
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 text-center">
                    <input
                      type="file"
                      name="second_image"
                      className="d-none"
                      onChange={(e) => onSelectFile(e, "second")}
                      ref={second_image_ref}
                    />
                    <img
                      src={
                        second_image || previewSecondImage
                          ? previewSecondImage
                          : `/images/image_default.jpg`
                      }
                      className="preview"
                      alt="pos_stock"
                      onClick={openSecondImageFileRef}
                    />
                    {productState.second_image_id && (
                      <button
                        type="button"
                        className="btn btn-outline-danger mt-3"
                        onClick={() =>
                          removeSecondImage(productState.second_image_id)
                        }
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">ชื่อสินค้า</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="กรุณากรอกชื่อสินค้า"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.errors.name && (
                    <span className="text-danger">{formik.errors.name}</span>
                  )}
                </div>
                <div className="form-group required">
                  <label>รายละเอียดสินค้า</label>
                  <textarea
                    name="description"
                    className="form-control"
                    rows="3"
                    placeholder="กรุณากรอกรายละเอียดสินค้า"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  ></textarea>
                  {formik.errors.description && (
                    <span className="text-danger">
                      {formik.errors.description}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group required d-flex flex-column">
                  <label htmlFor="exampleInputEmail1">ซื้อมาจาก</label>
                  <Autocomplete
                    value={formik.values.agent}
                    inputProps={{
                      id: "agent-autocomplete",
                      class: "form-control",
                      placeholder: "กรุณากรอกแหล่งที่ซื้อ",
                    }}
                    wrapperStyle={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    items={agentLists}
                    getItemValue={(item) => item.name}
                    shouldItemRender={matchStateToTermAgent}
                    onChange={(e) =>
                      formik.setFieldValue("agent", e.target.value)
                    }
                    onSelect={(value) => formik.setFieldValue("agent", value)}
                    renderMenu={(children) => (
                      <div className="menu">{children}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "item-highlighted" : ""
                        }`}
                        key={item.id}
                      >
                        {item.name}
                      </div>
                    )}
                  />
                  {formik.errors.agent && (
                    <span className="text-danger">{formik.errors.agent}</span>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group required d-flex flex-column">
                  <label htmlFor="exampleInputEmail1">
                    บาร์โค้ดเดิมของสินค้า
                  </label>
                  <Autocomplete
                    value={formik.values.custom_barcode}
                    inputProps={{
                      id: "barcode-autocomplete",
                      class: "form-control",
                      placeholder: "รหัสบาร์โค้ด",
                    }}
                    wrapperStyle={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    items={barcodeLists}
                    getItemValue={(item) => item.code}
                    shouldItemRender={matchStateToTermBarcode}
                    onChange={(e) => {
                      formik.setFieldValue("generate_barcode", "");
                      formik.setFieldValue("custom_barcode", e.target.value);
                      formik.setFieldValue("barcode", e.target.value);
                    }}
                    onSelect={(value) => {
                      formik.setFieldValue("generate_barcode", "");
                      formik.setFieldValue("custom_barcode", value);
                      formik.setFieldValue("barcode", value);
                    }}
                    renderMenu={(children) => (
                      <div className="menu">{children}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "item-highlighted" : ""
                        }`}
                        key={item.id}
                      >
                        {item.code}
                      </div>
                    )}
                  />
                  {formik.errors.barcode && (
                    <span className="text-danger">{formik.errors.barcode}</span>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">สร้างบาร์โค้ดเอง</label>
                  <input
                    name="barcode"
                    type="text"
                    className="form-control"
                    placeholder="รหัสบาร์โค้ด"
                    onChange={(e) => {
                      formik.setFieldValue("custom_barcode", "");
                      formik.setFieldValue("generate_barcode", e.target.value);
                      formik.setFieldValue("barcode", e.target.value);
                    }}
                    value={formik.values.generate_barcode || ""}
                  />
                  {formik.errors.barcode && (
                    <span className="text-danger">{formik.errors.barcode}</span>
                  )}
                </div>
              </div>
              {formik.values.barcode && (
                <div className="col-12 text-center">
                  <Barcode value={formik.values.barcode} />
                </div>
              )}
              <div className="col-sm-12 col-md-6 pt-3">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    พาทเบอร์อะไหล่ (อะไหล่ของแท้)
                  </label>
                  <input
                    name="part_size"
                    type="text"
                    className="form-control"
                    placeholder="พาทเบอร์อะไหล่ (อะไหล่ของแท้)"
                    onChange={formik.handleChange}
                    value={formik.values.part_size}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-3">
                <div className="form-group d-flex flex-column">
                  <label htmlFor="exampleInputEmail1">ตำแหน่งสต็อคสินค้า</label>
                  <Autocomplete
                    value={formik.values.treasury}
                    inputProps={{
                      id: "treasury-autocomplete",
                      class: "form-control",
                      placeholder: "ตำแหน่งสต็อคสินค้า",
                    }}
                    wrapperStyle={{
                      position: "relative",
                      display: "inline-block",
                    }}
                    items={treasuryLists}
                    getItemValue={(item) => item.place}
                    shouldItemRender={matchStateToTermTreasury}
                    onChange={(e) =>
                      formik.setFieldValue("treasury", e.target.value)
                    }
                    onSelect={(value) =>
                      formik.setFieldValue("treasury", value)
                    }
                    renderMenu={(children) => (
                      <div className="menu">{children}</div>
                    )}
                    renderItem={(item, isHighlighted) => (
                      <div
                        className={`item ${
                          isHighlighted ? "item-highlighted" : ""
                        }`}
                        key={item.id}
                      >
                        {item.place}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-3">
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">
                    ยอดสูงสุดในการสต็อค
                  </label>
                  <input
                    name="maximum_total"
                    type="text"
                    className="form-control text-right"
                    placeholder="ยอดสูงสุดในการสต็อค"
                    onChange={formik.handleChange}
                    value={formik.values.maximum_total}
                  />
                  {formik.errors.maximum_total && (
                    <span className="text-danger">
                      {formik.errors.maximum_total}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-3">
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">แจ้งเตือนสั่งซื้อ</label>
                  <input
                    name="warning_total"
                    type="text"
                    className="form-control text-right"
                    placeholder="แจ้งเตือนสั่งซื้อ"
                    onChange={formik.handleChange}
                    value={formik.values.warning_total}
                  />
                  {formik.errors.warning_total && (
                    <span className="text-danger">
                      {formik.errors.warning_total}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-3">
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">จำนวนต่อแพ็ค</label>
                  <input
                    name="per_pack_total"
                    type="text"
                    className="form-control text-right"
                    placeholder="จำนวนต่อแพ็ค"
                    onChange={formik.handleChange}
                    value={formik.values.per_pack_total}
                  />
                  {formik.errors.per_pack_total && (
                    <span className="text-danger">
                      {formik.errors.per_pack_total}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-3">
                <div className="form-group required">
                  <label htmlFor="exampleInputEmail1">P</label>
                  <input
                    name="p"
                    type="text"
                    className="form-control text-right"
                    placeholder="p"
                    onChange={formik.handleChange}
                    value={formik.values.p}
                  />
                  {formik.errors.p && (
                    <span className="text-danger">{formik.errors.p}</span>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 pt-3">
                    <div className="form-group d-flex flex-column">
                      <label htmlFor="exampleInputEmail1">สินค้ารอง</label>
                      <Autocomplete
                        value={searchProduct}
                        inputProps={{
                          id: "subProduct-autocomplete",
                          class: "form-control",
                          placeholder: "สินค้ารอง",
                          disabled: !isNaN(formik.values.per_pack_total) && +formik.values.per_pack_total <= 0
                        }}
                        wrapperStyle={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        items={productLists}
                        getItemValue={(item) => item.id}
                        shouldItemRender={matchStateToTermAgent}
                        onChange={e => setSearchProduct(e.target.value)}
                        onSelect={(value) => {
                          formik.setFieldValue("sub_product_id", value)
                            if(productLists.find(p => p.id === value)) {
                          setSearchProduct(productLists.find(p => p.id === value).barcode.code)
                          }
                        }}
                        renderMenu={(children) => (
                          <div className="menu">{children}</div>
                        )}
                        renderItem={(item, isHighlighted) => (
                          <div
                            className={`item ${
                              isHighlighted ? "item-highlighted" : ""
                            }`}
                            key={item ? item.id : ''}
                          >
                            {item.barcode ? item.barcode.code : ''}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  {
                    productLists.find(p => p.id === formik.values.sub_product_id) && (
                      <div className="col-sm-12 col-md-6 pt-3">
                        <div className="form-group d-flex flex-column">
                          <label htmlFor="exampleInputEmail1">ชื่อสินค้ารอง</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled
                            value={productLists.find(p => p.id === formik.values.sub_product_id).name}
                            />
                        </div>
                      </div>
                    )
                  }
              <div className="col-12">
                <div className="form-group required">
                  <label>ประเภท Vat ของสินค้า</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="has_vat"
                      value="1"
                      onChange={(e) =>
                        formik.setFieldValue("has_vat", e.target.value)
                      }
                      checked={formik.values.has_vat === "1"}
                    />
                    <label className="form-check-label">มี vat</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="has_vat"
                      value="0"
                      onChange={(e) =>
                        formik.setFieldValue("has_vat", e.target.value)
                      }
                      checked={formik.values.has_vat === "0"}
                    />
                    <label className="form-check-label">ไม่มี vat</label>
                  </div>
                  {formik.errors.has_vat && (
                    <span className="text-danger">{formik.errors.has_vat}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            {isLoading ? (
              <button type="button" className="btn btn-primary">
                <i className="fas fa-spinner fa-spin"></i>
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                <i className="fas fa-save"></i> บันทึก
              </button>
            )}
            <Link to={`/products?page=${query.page ? query.page : 1}&search=${query.search ? query.search : ''}`}>
              <button
                type="button"
                className="btn btn-secondary float-right"
                disabled={isLoading}
              >
                <i className="fas fa-window-close"></i> ยกเลิก
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(EditForm));
