import React, { Component } from "react";
import axios from "../config/service";
import { initUserData } from "../actions/users";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withAlert } from "react-alert";

export const withProfile = (WrappedComponent) => {
  class withProfile extends Component {
    componentDidMount() {
      this.getUserData();
    }

    getUserData = async () => {
      try {
        const user = await axios.get("auth/");
        const {
          data: {
            name,
            lastname,
            telephone,
            email,
            last_login,
            roles,
            permissions,
          },
        } = user;
        const userData = {
          name: name,
          lastname: lastname,
          telephone: telephone,
          email: email,
          last_login: last_login,
          token: localStorage.getItem("token"),
          roles,
          permissions,
        };
        this.props.initUserData(userData);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          this.props.alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
      }
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    initUserData: (value) => {
      dispatch(initUserData(value));
    },
  });

  return connect(
    null,
    mapDispatchToProps
  )(withRouter(withAlert()(withProfile)));
};
