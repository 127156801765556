export function matchStateToTermBarcode(state, value) {
  return (
    state.code.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
    state.id.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

export function matchStateToTermAgent(state, value) {
  return (
    state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
    state.id.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

export function matchStateToTermTreasury(state, value) {
  return (
    state.place.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
    state.id.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}
