import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "../../config/service";
import { withRouter, Link } from "react-router-dom";
import LoadingComponent from "../other/loading";
import SearchComponent from "../input/search";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setTitleHeader } from "../../actions/menus";
import { useAlert } from "react-alert";
import moment from "moment";
import * as qs from 'query-string';

const Lists = ({ location, setTitleHeader }) => {
  const alert = useAlert();
  const [isLoading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [products, setProducts] = useState([]);
  const [seletedProduct, setSelectedProduct] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [productTotal, setProductTotal] = useState(0);
  const [pageNow, setPageNow] = useState(0);
  const isMounted = useRef(true);

  const fetchProducts = useCallback(
    async (page = 1, search = "") => {
      try {
        const productsAPI = await axios.get(
          `products?page=${page}&search=${search}`
        );
        const {
          data: {
            products: { data, last_page },
          },
        } = productsAPI;
        setProductTotal(last_page);
        setProducts(data);
        setLoading(false);
        setPageNow(page);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
      }
    },
    [alert]
  );

  const onClickDeleteProduct = useCallback(
    async (product) => {
      if (deleting) {
        alert.show("กำลังลบข้อมูลก่อนหน้าอยู่กรุณารอสักครู่.");
        return;
      }
      if (product) {
        setDeleting(true);
        setLoading(true);
        try {
          await axios.post(`products/delete/${product.id}`);
          await fetchProducts();
          setDeleting(false);
          setSelectedProduct(null);
          setLoading(false);
          alert.success("ลบข้อมูลสำเร็จ.");
        } catch (error) {
          if (error.response && error.response.status === 500) {
            alert.error("มีข้อผิดพลาดเกิดขึ้น.");
          }
          setDeleting(false);
          setSelectedProduct(null);
          setLoading(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleting, fetchProducts]
  );

  const handlePageClick = useCallback(
    async (data) => {
      setLoading(true);
      fetchProducts(data.selected + 1, searchText);
    },
    [fetchProducts, searchText]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const parseQuery = qs.parse(location.search)
    const page = parseQuery.page ? parseQuery.page : 1
    const search = parseQuery.search ? parseQuery.search : ''
    fetchProducts(page, search);
    setSearchText(search)
    setTitleHeader({
      title: "จัดการข้อมูลสินค้า",
      href: "/products",
    });
    return () => {
      isMounted.current = false;
    };
  }, [fetchProducts, location.search, setTitleHeader]);

  const searchOnType = useCallback(
    async (value) => {
      setSearchText(value);
      setLoading(true);
      fetchProducts(1, value);
    },
    [fetchProducts]
  );

  return (
    <>
      <div>
        <LoadingComponent isLoading={isLoading} />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            {/* Search form */}
            <SearchComponent value={searchText} onSearch={searchOnType} />
          </div>
          <div className="col-sm-12 col-md-6 text-md-right">
            <Link to={`/products/add?page=${pageNow}&search=${searchText}`}>
              <button type="button" className="btn btn-success">
                + เพิ่มข้อมูล
              </button>
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="card">
              {/* /.card-header */}
              <div className="card-body p-0">
                <div className="table-responsive text-nowrap">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="text-left">บาร์โคด</th>
                        <th className="text-left">จัดการ</th>
                        <th className="text-left">ลำดับ</th>
                        <th className="text-left">รูปสินค้า</th>
                        <th className="text-left">ชื่อสินค้า</th>
                        {
                          (products.length > 0 && products.some(p => p.last_cost_price !== null)) && (
                            <th className="text-center">ต้นทุน</th>
                          )
                        }
                        <th className="text-left">ซื้อมาจาก</th>
                        <th className="text-left">
                          ตำแหน่ง
                          <br />
                          สต๊อกสินค้า
                        </th>
                        <th className="text-left">
                          ยอดสูงสุด
                          <br />
                          ในการสต็อก
                        </th>
                        <th className="text-left">
                          แจ้งเตือน
                          <br />
                          สั่งซื้อ
                        </th>
                        <th className="text-left">
                          สต็อก
                          <br />
                          คงเหลือ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product, idx) => {
                        return (
                          <tr key={product.id}>
                            <td>{product.barcode ? product.barcode.code : ''}</td>
                            <td className="text-center d-flex justify-content-around">
                              <Link to={`/products/edit/${product.id}?page=${pageNow}&search=${searchText}`}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </Link>
                              {!product.has_warehouse && (
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  data-toggle="modal"
                                  data-target="#exampleModal"
                                  onClick={() => setSelectedProduct(product)}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              )}
                            </td>
                            <td>{(pageNow - 1) * 10 + (idx + 1)}</td>
                            <td>
                              {product.images.length > 0 ? (
                                product.images.length === 1 ? (
                                  <img
                                    src={product.images[0].path.thumbnail}
                                    alt={product.name}
                                    className="avatar"
                                  ></img>
                                ) : (
                                  product.images.map((image, idximg) => {
                                    return (
                                      <img
                                        src={image.path.thumbnail}
                                        alt={product.name}
                                        className="avatar"
                                        key={idximg}
                                      ></img>
                                    );
                                  })
                                )
                              ) : (
                                <>
                                  <img
                                    src={`images/image_default.jpg`}
                                    alt={product.name}
                                    className="avatar"
                                  ></img>
                                  <img
                                    src={`images/image_default.jpg`}
                                    alt={product.name}
                                    className="avatar"
                                  ></img>
                                </>
                              )}
                            </td>
                            <td>{product.name}</td>
                            {product.last_cost_price !== null && 
                              <td className="text-center">
                                <div style={{fontWeight: 'bold', color: 'red'}}>{product.last_cost_price ? product.last_cost_price.price : '-'}</div>
                                <span>{moment(product.last_cost_price.date).add(543, "years").format('DD/MM/YY')}</span>
                              </td>
                            }
                            {product.last_cost_price === null && <td className="text-center">-</td>}
                            <td>{product.agent_embed}</td>
                            <td>
                              {product.treasury ? product.treasury.place : "-"}
                            </td>
                            <td className="text-right">
                              {product.maximum_total}
                            </td>
                            <td className="text-right">
                              {product.warning_total}
                            </td>
                            <td className="text-right">
                              {product.current_stock}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ReactPaginate
                forcePage={+pageNow - 1}
                previousLabel={"ก่อนหน้า"}
                nextLabel={"ต่อไป"}
                pageCount={productTotal}
                pageRangeDisplayed={5}
                previousClassName={"page-item"}
                marginPagesDisplayed={1}
                nextClassName={"page-item"}
                pageClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </nav>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                ยืนยันการลบสินค้า
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">คุณต้องการลบสินค้าชิ้นนี้หรือไม่ ?</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => onClickDeleteProduct(seletedProduct)}
                data-dismiss="modal"
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(null, mapDispatchToProps)(withRouter(Lists));
