import React from "react";
import moment from "moment";
import currency from "currency.js";

class ComponentToPrint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: moment().format("DD MMMM YYYY เวลา HH:mm:ss น."),
    };
  }
  componentDidMount() {
    this.intervalID = setInterval(() => this.tick(), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  tick() {
    this.setState({
      time: moment().format("DD MMMM YYYY เวลา HH:mm:ss น."),
    });
  }

  render() {
    const {
      products,
      paymentChannel,
      customer: { name = "", address = "", tel = "" },
      owner,
      customerType,
    } = this.props;
    const sum = products.reduce((a, b) => {
      const productPrice = b.product_prices.find(
        (value) => value.customer_type_id === customerType.value
      );
      const price = productPrice ? +productPrice.price * +b.choose_total : 0;
      return a + price;
    }, 0);
    return (
      <div className="pos-container">
        <div className="title">POS-STOCK</div>
        <div className="sub-title mt-1" style={{ fontWeight: "bold" }}>
          วันที่ออกรายการ
        </div>
        <div className="sub-title">{this.state.time}</div>
        <div className="sub-title" >
          <span style={{ fontWeight: "bold" }}>ประเภทลูกค้า: </span> <span>{customerType.display}</span>
        </div>
        <div className="sub-title">
          <span style={{ fontWeight: "bold" }}>ช่องทางการชำระเงิน: </span> <span>{paymentChannel}</span>
        </div>
        <div className="sub-title">
          <span style={{ fontWeight: "bold" }}>ชื่อลูกค้า: </span> <span>{name}</span>
        </div>
        <div className="sub-title">
          <span style={{ fontWeight: "bold" }}>ที่อยู่: </span> <span>{address}</span>
        </div>
        <div className="sub-title">
          <span style={{ fontWeight: "bold" }}>เบอร์โทรศัพท์ T.</span> <span>{tel}</span>
        </div>
        <div className="sub-title mt-1" style={{ fontWeight: "bold" }}>
          รายการสินค้าที่เลือก
        </div>
        <table className="table-receipt">
          <tbody>
            <tr className="table-title">
              <td className="name-deal-title-column text-left">สินค้า</td>
              <td className="quantity-title-column text-right">Q.</td>
              <td className="price-title-column text-right">ราคา.</td>
              <td className="all-price-title-column text-right">รวม</td>
            </tr>
            {products.map((product) => {
              const productPrice = product.product_prices.find(
                (value) => value.customer_type_id === customerType.value
              );
              return (
                <tr className="order" key={product.id}>
                  <td className="text-left">{product.name}</td>
                  <td className="text-right">{product.choose_total}</td>
                  <td className="text-right">{productPrice
                      ? currency(+productPrice.price, {
                          separator: ",",
                          symbol: "",
                        }).format()
                      : 0}</td>
                  <td className="text-right">
                    {productPrice
                      ? currency(+productPrice.price * +product.choose_total, {
                          separator: ",",
                          symbol: "",
                        }).format()
                      : 0}
                  </td>
                </tr>
              );
            })}
            <tr>
              <td
                colspan="2"
                className="text-left pt-3"
                style={{ fontWeight: "bold", fontSize: "18px" }}
              >
                ราคารวม
              </td>
              <td
                className="text-right pt-3"
                style={{ fontWeight: "bold", fontSize: "18px" }}
              >
                {currency(sum, { separator: ",", symbol: "" }).format()}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="border-under-table"></div>
        <div className="sub-title my-3">ผู้พิมพ์ : {owner}</div>
        <div className="border-under-table"></div>
      </div>
    );
  }
}

export default ComponentToPrint;
