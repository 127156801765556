import React, { Component } from "react";
import LoginComponent from "../login";

class UnAuthorization extends Component {
  render() {
    return <LoginComponent />;
  }
}

export default UnAuthorization;
