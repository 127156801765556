import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <>
        {/* Control Sidebar */}
        <aside className="control-sidebar control-sidebar-dark">
          {/* Control sidebar content goes here */}
        </aside>
        {/* /.control-sidebar */}
        <footer className="main-footer">
          <strong>Copyright © 2019-2020 POS-STOCK.</strong>
          All rights reserved.
          {/* <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 3.0.1
          </div> */}
        </footer>
        <div id="sidebar-overlay"></div>
      </>
    );
  }
}
