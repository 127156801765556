import axios from "axios";
import React from "react";
import { withRouter } from "react-router-dom";
import { initUserData } from "../../actions/users";
import { connect } from "react-redux";
import Button from "../input/button";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      isLoading: false,
      authenFail: false
    };
  }

  async handleChange(name, value) {
    this.setState({
      [name]: value
    });
  }

  login = async () => {
    const { username, password } = this.state;
    this.setState({
      isLoading: true
    });
    try {
      const data = {
        username: username,
        password: password
      };
      const users = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}auth/login`, data);
      Promise.resolve()
        .then(() => {
          localStorage.setItem("token", users.data.access_token);
        })
        .then(() => {
          this.props.history.push("/");
        });
    } catch (e) {
      this.setState({
        isLoading: false,
        authenFail: true
      });
    }
  };

  render() {
    const { username, password, isLoading, authenFail } = this.state;
    return (
      <div className="login-container">
        <div className="login-box">
          <div className="login-logo">
            <b>เข้าสู่ระบบ</b>
          </div>
          {/* /.login-logo */}
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">กรุณากรอกข้อมูลผู้ใช้งาน</p>
              {authenFail && (
                <div className="alert alert-danger" role="alert">
                  ชื่อผู้ใช้หรือรหัสผ่านผิดพลาด.
                </div>
              )}
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="อีเมล"
                  value={username}
                  onChange={e => this.handleChange("username", e.target.value)}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      this.login();
                    }
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="รหัสผ่าน"
                  value={password}
                  onChange={e => this.handleChange("password", e.target.value)}
                  onKeyPress={e => {
                    if (e.key === "Enter") {
                      this.login();
                    }
                  }}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* /.col */}
                <div className="col-12">
                  <Button
                    type="button"
                    className="btn btn-primary btn-block"
                    onClick={this.login}
                    isLoading={isLoading}
                  >
                    เข้าสู่ระบบ
                  </Button>
                </div>
                {/* /.col */}
              </div>
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  initUserData: value => {
    dispatch(initUserData(value));
  }
});

export default connect(null, mapDispatchToProps)(withRouter(Login));
