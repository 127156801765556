import * as axios from "axios";
import { history } from "../routing.js";

const instance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_URL,
});

let isRefreshToken = true;

instance.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      const token = localStorage.getItem("token");
      if (!config.headers.Authorization) {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      if (isRefreshToken) {
        isRefreshToken = false;
        try {
          const refreshToken = await instance(`auth/refresh`);
          const {
            data: { access_token },
          } = refreshToken;
          localStorage.setItem("token", access_token);
          window.location.reload();
        } catch (error) {
          if (error.response.status === 401) {
            isRefreshToken = true;
            localStorage.removeItem("token");
            history.push("/login");
          }
        }
      } else {
        if (error.response.status === 401) {
          isRefreshToken = true;
          localStorage.removeItem("token");
          history.push("/login");
        }
      }
    }
    return Promise.reject(error);
  }
);
export default instance;
