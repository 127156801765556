import React, { Component } from "react";
import Header from "../../Header";
import Menu from "../../Menu";
import Footer from "../../Footer";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Authorization extends Component {
  render() {
    const {
      user: { roles, permissions },
      permissionAccess
    } = this.props;
    if (roles.length > 0) {
      if (roles[0].name !== "Super Admin") {
        if (permissions.length > 0 && permissionAccess) {
          const hasPermission = permissions.filter(
            permission => permission.name === permissionAccess
          );
          if (!hasPermission.length) {
            return <Redirect to="/" />;
          }
        } else if(permissionAccess) {
          return <Redirect to="/" />;
        }
      }
    }
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">{this.props.children}</div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps, null)(Authorization);
