import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setTitleHeader } from "../actions/menus";

const Index = ({ setTitleHeader }) => {
  useEffect(() => {
    setTitleHeader({
      title: "หน้าแรก",
      href: "/"
    });
  }, [setTitleHeader]);
  return <>Index</>;
};

const mapDispatchToProps = dispatch => ({
  setTitleHeader: value => {
    dispatch(setTitleHeader(value));
  }
});

export default connect(null, mapDispatchToProps)(Index);
