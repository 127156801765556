import React from "react";
import axios from "../../config/service";
import moment from "moment";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import LoadingComponent from "../other/loading";
import SearchComponent from "../input/search";
import { connect } from "react-redux";
import { setTitleHeader } from "../../actions/menus";
import { withAlert } from "react-alert";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      userTotal: 0,
      isLoading: true,
      searchText: "",
      pageNow: 0,
      selectedUser: null,
      deleting: false
    };
  }

  componentDidMount() {
    this.fetchUserLists();
    this.props.setTitleHeader({
      title: "จัดการข้อมูลพนักงาน",
      href: "/users"
    });
  }

  fetchUserLists = async (page = 1, search = "") => {
    try {
      const usersAPI = await axios.get(
        `employee?page=${page}&search=${search}`
      );
      const {
        data: {
          users: { data, last_page }
        }
      } = usersAPI;
      this.setState({
        users: data,
        userTotal: last_page,
        isLoading: false,
        pageNow: page
      });
    } catch (e) {
      console.log(e);
    }
  };

  onClickDeleteUser = async user => {
    if (this.state.deleting) {
      this.props.alert.show("กำลังลบข้อมูลก่อนหน้าอยู่กรุณารอสักครู่.");
      return;
    }

    if (user) {
      this.setState({
        isLoading: true,
        deleting: true
      });
      try {
        await axios.post(`employee/delete/${user.id}`);
        await this.fetchUserLists();
        this.setState({
          isLoading: false,
          deleting: false
        });
      } catch (error) {
        console.log(error);
        this.setState({
          isLoading: false,
          deleting: false
        });
      }
    }
  };

  handlePageClick = async data => {
    this.setState({
      isLoading: true
    });
    this.fetchUserLists(data.selected + 1, this.state.searchText);
  };

  searchOnType = async value => {
    this.setState({
      isLoading: true,
      searchText: value
    });
    this.fetchUserLists(1, value);
  };

  render() {
    const { users, userTotal, pageNow, isLoading, selectedUser } = this.state;
    return (
      <>
        <LoadingComponent isLoading={isLoading} />
        <div className="row">
          <div className="col-sm-12 col-md-6">
            {/* Search form */}
            <SearchComponent onSearch={this.searchOnType} />
          </div>
          <div className="col-sm-12 col-md-6 text-md-right">
            {/* <Link href="/users/add"> */}
            <Link to="/users/add">
              <button type="button" className="btn btn-success">
                + เพิ่มข้อมูล
              </button>
              {/* </Link> */}
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="card">
              {/* /.card-header */}
              <div className="card-body p-0">
                <div className="table-responsive text-nowrap">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>ลำดับ</th>
                        <th>ชื่อ - สกุล</th>
                        <th>เบอร์โทร</th>
                        <th>อีเมล</th>
                        <th>ตำแหน่ง</th>
                        <th>ล็อคอินล่าสุด</th>
                        <th className="text-center">จัดการ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user, idx) => {
                        return (
                          <tr key={user.id}>
                            <td>{(pageNow - 1) * 10 + (idx + 1)}</td>
                            <td>{`${user.name} ${user.lastname}`}</td>
                            <td>{user.telephone}</td>
                            <td>{user.email}</td>
                            <td>
                              {user.roles.length > 0 ? user.roles[0].name : "-"}
                            </td>
                            <td>
                              {user.last_login
                                ? moment(user.last_login).format(
                                    "DD-MM-YYYY HH:mm"
                                  )
                                : "-"}
                            </td>
                            <td className="text-center d-flex justify-content-around">
                              <Link to={`/users/edit/${user.id}`}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                >
                                  <i className="fas fa-edit"></i>
                                </button>
                              </Link>
                              <button
                                type="button"
                                className="btn btn-danger"
                                data-toggle="modal"
                                data-target="#exampleModal"
                                onClick={() =>
                                  this.setState({ selectedUser: user })
                                }
                              >
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ReactPaginate
                previousLabel={"ก่อนหน้า"}
                nextLabel={"ต่อไป"}
                pageCount={userTotal}
                pageRangeDisplayed={5}
                previousClassName={"page-item"}
                marginPagesDisplayed={1}
                nextClassName={"page-item"}
                pageClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </nav>
          </div>
        </div>
        <div
          className="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  ยืนยันการลบข้อมูลผู้ใช้
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                คุณต้องการลบข้อมูลผู้ใช้หรือไม่ ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  ปิด
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.onClickDeleteUser(selectedUser)}
                  data-dismiss="modal"
                >
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setTitleHeader: value => {
    dispatch(setTitleHeader(value));
  }
});

export default connect(null, mapDispatchToProps)(withAlert()(Index));
