import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "../../config/service";
import LoadingComponent from "../other/loading";
import SearchComponent from "../input/search";
import { setTitleHeader } from "../../actions/menus";
import { connect } from "react-redux";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import moment from "moment";
import Carousel from "../carousel/Carousel";

const schema = Yup.object().shape({
  price: Yup.string()
    .trim()
    .matches(/^\d+(\.\d{1,2})?$/, "กรุณากรอกเฉพาะตัวเลขและทศนิยมไม่เกิน 2 หลัก")
    .required("กรุณากรอกราคา"),
});

const Pricing = ({ rimProps, rsProps, setTitleHeader }) => {
  const alert = useAlert();
  const [product, setProduct] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateError, setValidateError] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [customerType, setCustomerType] = useState([]);
  const [historyPrice, setHistoryPrice] = useState([]);
  const [isProductExists, setIsProductExists] = useState(true)
  const isMounted = useRef(true);

  useEffect(() => {
    setTitleHeader({
      title: "จัดการราคาขาย",
      href: "/pricing",
    });
    return () => {
      isMounted.current = false;
    };
  }, [setTitleHeader]);

  const searchOnType = useCallback(async (value) => {
    const fetchProductsByBarcode = async (value) => {
      try {
        const barcodeAPI = await axios.get(`barcodes/id?value=${value}`);
        const {
          data: { barcode },
        } = barcodeAPI;
        if (barcode) {
          const productPriceAPI = await axios.get(
            `products/${barcode.product.id}`
          );
          const {
            data: {
              product: { customers },
            },
          } = productPriceAPI;
          const customerTypeAPI = await axios.get("customer/type");
          const {
            data: { customer_type },
          } = customerTypeAPI;
          const historyPriceAPI = await axios.get(
            `products/prices/${barcode.product.id}`
          );
          const {
            data: { history_price, product_exists },
          } = historyPriceAPI;
          const mapCustomers = customers.map((customer) => {
            if (customer.product_prices === null) {
              return {
                ...customer,
                product_prices: {
                  customer_type_id: customer.id,
                  price: "0",
                },
              };
            }
            return customer;
          });
          setIsSearch(true);
          setCustomers(product_exists ? mapCustomers : []);
          setProduct(product_exists ? barcode.product : null);
          setCustomerType(product_exists ? customer_type : []);
          setHistoryPrice(product_exists ? history_price : []);
          setNotFound(false);
          setIsProductExists(product_exists)
        } else {
          setCustomers([]);
          setProduct(null);
          setNotFound(true);
        }
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
        setCustomers([]);
        setProduct(null);
        setNotFound(true);
      }
    };
    setLoading(true);
    fetchProductsByBarcode(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePriceChange = (event, idx) => {
    const value = event.target.value;
    let getCustomers = [...customers];
    schema
      .validate({
        price: value,
      })
      .then(() => {
        getCustomers[idx].product_prices["price"] = value;
        getCustomers[idx].product_prices["error"] = false;
        getCustomers[idx].product_prices["errorMessage"] = "";
        setCustomers(getCustomers);
        setValidateError(false);
      })
      .catch((error) => {
        getCustomers[idx].product_prices["price"] = value;
        getCustomers[idx].product_prices["error"] = true;
        getCustomers[idx].product_prices["errorMessage"] = error.message;
        setCustomers(getCustomers);
        setValidateError(true);
      });
  };

  const onClickUpdatePrice = useCallback(async () => {
    if (loading) {
      return;
    }
    if (!product) {
      alert.show("กรุณาเลือกสินค้าก่อนกดบันทึก !");
      return;
    }
    if (validateError) {
      alert.show("กรุณากรอกข้อมูลให้ถูกต้อง หรือ เป็นข้อมูลราคาใหม่เท่านั้น.");
      return;
    }
    const getCustomers = [...customers];
    const customersData = getCustomers.map((customer) => {
      return {
        customer_type_id: customer.id,
        price: customer.product_prices.price,
      };
    });
    setBtnLoading(true);
    setLoading(true);
    setIsSuccess(false);
    try {
      await axios.post(`products/prices/${product.id}`, {
        product_prices: customersData,
      });
      const historyPriceAPI = await axios.get(`products/prices/${product.id}`);
      const {
        data: { history_price },
      } = historyPriceAPI;
      setLoading(false);
      setBtnLoading(false);
      setValidateError(true);
      setIsSuccess(true);
      setHistoryPrice(history_price);
      window.scrollTo(0, 0);
    } catch (error) {
      setLoading(false);
      setBtnLoading(false);
      setValidateError(true);
      if (error.response && error.response.status === 500) {
        alert.error("มีข้อผิดพลาดเกิดขึ้น.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, loading, product, validateError]);

  return (
    <>
      <LoadingComponent isLoading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">กำหนดราคาขาย</h3>
            </div>
            {/* /.card-header */}
            {/* form start */}
            <div className="card-body">
              <div className="row pb-3">
                {isSuccess && (
                  <div className="col-12">
                    <div class="alert alert-success" role="alert">
                      บันทึกข้อมูลสำเร็จ.
                    </div>
                  </div>
                )}
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">บาร์โคด</label>
                    <SearchComponent onSearch={searchOnType} />
                  </div>
                  {isSearch &&
                    (notFound || !product ? (
                      <div className="alert alert-danger" role="alert">
                        {isProductExists ? 'ไม่พบสินค้า' : 'สินค้านี้ยังไม่ได้ถูกเพิ่มเข้าคลัง'}.
                      </div>
                    ) : (
                      <p>ชื่อสินค้า : {product ? product.name : "-"}</p>
                    ))}
                </div>
                <div className="col-sm-12 col-md-6">
                  <Carousel images={product ? product.images : []} />
                </div>
              </div>
              {isSearch && !notFound && customers.length > 0 && (
                <div className="row mt-2">
                  <div className="col-12">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>วันที่-เวลา ล่าสุด</th>
                          <th>ประเภทลูกค้า</th>
                          <th className="text-right">ราคาขาย (บาท)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customers.map((customer, idx) => {
                          return (
                            <tr key={customer.id}>
                              <td>
                                {moment(
                                  customer.product_prices.created_at
                                ).format("DD/MM/YYYY HH:mm")}
                              </td>
                              <td>{customer.type}</td>
                              <td className="text-right">
                                <input
                                  className="form-control text-right"
                                  type="text"
                                  placeholder="จำนวน"
                                  onChange={(e) => handlePriceChange(e, idx)}
                                  defaultValue={
                                    customer.product_prices
                                      ? customer.product_prices.price
                                      : ""
                                  }
                                />
                                {customer.product_prices &&
                                  customer.product_prices.error && (
                                    <span className="text-danger">
                                      {customer.product_prices.errorMessage}
                                    </span>
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-12">
                    <div className="table-responsive text-nowrap">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>วันที่-เวลา</th>
                            {customerType.map((type) => {
                              return <th key={type.id}>{type.type}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {historyPrice.map((history, idx) => {
                            return (
                              <tr key={history.id}>
                                <td>
                                  {moment(history.created_at).format(
                                    "DD/MM/YYYY HH:mm"
                                  )}
                                </td>
                                <td>
                                  {history.customers[0].price.toLocaleString()}
                                </td>
                                <td>
                                  {history.customers[1].price.toLocaleString()}
                                </td>
                                <td>
                                  {history.customers[2].price.toLocaleString()}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isSearch && !notFound && customers.length > 0 && (
              <div className="card-footer text-center">
                {btnLoading ? (
                  <button type="button" className="btn btn-primary">
                    <i className="fas fa-spinner fa-spin"></i>
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => onClickUpdatePrice()}
                  >
                    <i className="fas fa-save"></i> ยืนยันโครงสร้างราคา
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(null, mapDispatchToProps)(Pricing);
