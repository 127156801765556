import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "../../config/service";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { setTitleHeader } from "../../actions/menus";
import { useAlert } from "react-alert";
import LoadingComponent from "../other/loading";
import ReactToPrint from "react-to-print";
import moment from "moment";
import * as Yup from "yup";

var Barcode = require("react-barcode");
var QRCode = require("qrcode.react");

const schema = Yup.object().shape({
  price: Yup.number("กรุณากรอกเฉพาะตัวเลข")
    .typeError("กรุณากรอกเฉพาะตัวเลข")
    .integer("กรุณากรอกเฉพาะตัวเลข")
    .min(1, "กรุณากรอกจำนวนอย่างน้อยหนึ่งจำนวน")
    .required("กรุณากรอกจำนวน"),
});

class ComponentToPrint extends React.Component {
  render() {
    const { label } = this.props;
    return (
      <div>
        {label.map((elem, idx) => {
          return (
            <div className="barcode-label-container px-3" key={idx}>
              <div className="d-flex flex-row mb-auto pt-2 pl-2">
                <div>
                  <QRCode
                    style={{ width: 70, height: 70 }}
                    value={`${process.env.REACT_APP_BASE_URL}#/products/edit/${elem.productId}`}
                  />
                </div>
                <div className="ml-3">
                  <div className="detail-barcode-print block-with-text">
                    {elem.productName}
                  </div>
                  <div className="d-flex flex-row">
                    <div
                      className="detail-barcode-print"
                      style={{ width: "2.5cm" }}
                    >
                      {elem.treasury}
                    </div>
                    <div
                      className="detail-barcode-print ml-auto"
                      style={{
                        width: "1.5cm",
                        fontSize: "0.3cm",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      {elem.p}
                    </div>
                  </div>
                </div>
              </div>
              <div className="barcode-print-container">
                <Barcode
                  value={elem.value}
                  renderer={"img"}
                  displayValue={false}
                />
              </div>
              <div className="barcode-text">{elem.value}</div>
              <div className="date-text">{moment().add(543, 'years').format('DD-MM-YYYY HH:mm:ss')}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

const PrintLabel = ({ setTitleHeader }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productSelect, setProductSelect] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [searchObject, setSearchObject] = useState({
    text: "",
    products: [],
  });
  const [pageNow, setPageNow] = useState(0);
  const isMounted = useRef(true);
  const timeoutRef = useRef(null);
  const componentRef = useRef();

  const fetchProducts = useCallback(
    async (page = 1, { text: search = "", products: productSelected = [] }) => {
      setLoading(true);
      try {
        const productsAPI = await axios.get(
          `products?page=${page}&search=${search}&filter_sticker_page=1`
        );
        const {
          data: {
            products: { data, last_page },
          },
        } = productsAPI;
        const modifyProduct = data.map((product) => {
          const filterProductSelected = productSelected.filter(
            (elem) => elem.productId === product.id
          );

          return {
            id: product.id,
            name: product.name,
            treasury: product.treasury ? product.treasury.place : "-",
            amount: filterProductSelected.length,
            barcode: product.barcode ? product.barcode.code : "",
            total: "",
            total_error: false,
            total_error_message: "",
            p: product.p ? product.p : "-",
          };
        });
        setProductTotal(last_page);
        setProducts(modifyProduct);
        setLoading(false);
        setPageNow(page);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    fetchProducts(1, searchObject);
    setTitleHeader({
      title: "พิมพ์สติกเกอร์สินค้า",
      href: "/printlabel",
    });
    return () => {
      clearTimeout(timeoutRef.current);
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageClick = useCallback(
    async (data) => {
      fetchProducts(data + 1, searchObject);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchObject]
  );

  const handleTotalChange = useCallback(
    async (event, idx) => {
      const value = event.target.value;
      let getProducts = [...products];
      schema
        .validate({
          price: value,
        })
        .then(() => {
          getProducts[idx].total = value;
          getProducts[idx].total_error = false;
          getProducts[idx].total_error_message = "";
          setProducts(getProducts);
        })
        .catch((error) => {
          getProducts[idx].total = value;
          getProducts[idx].total_error = true;
          getProducts[idx].total_error_message = error.message;
          setProducts(getProducts);
        });
    },
    [products]
  );

  const handleChooseTotal = useCallback(
    async (idx) => {
      const isError = products[idx].total_error;
      const total = products[idx].total;
      if (isError || !total) {
        alert.show("กรุณากรอกข้อมูลเฉพาะตัวเลข.");
        return;
      }
      const productName = products[idx].name;
      let setProductValue = products;
      setProductValue[idx].total = "";
      setProductValue[idx].total_error = false;
      setProductValue[idx].total_error_message = "";
      setProductValue[idx].amount += +total;
      setProducts(setProductValue);
      const barcodeCreate = Array.from({ length: total }, () => {
        return {
          productId: products[idx].id,
          value: products[idx].barcode,
          productName: productName,
          amount: products[idx].amount,
          treasury: products[idx].treasury,
          p: products[idx].p,
        };
      });
      setProductSelect([...productSelect, ...barcodeCreate]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productSelect, products]
  );

  const handleClickDeleteBarcode = useCallback(
    async (productId, productSelectIdx) => {
      let setProductSelectValue = [...productSelect];
      let setProductValue = [...products];
      const filtersProductId = setProductValue.findIndex(
        (product) => product.id === productId
      );
      if (setProductValue.length > 0) {
        setProductValue[filtersProductId].amount -= 1;
        setProducts(setProductValue);
      }
      setProductSelect(
        setProductSelectValue.filter((_, idx) => idx !== productSelectIdx)
      );
    },
    [productSelect, products]
  );

  const handleClickDeleteAllBarcode = useCallback(() => {
    const productsValue = products;
    const setProductValue = productsValue.map((product) => {
      return {
        ...product,
        amount: 0,
      };
    });
    setProducts(setProductValue);
    setProductSelect([]);
  }, [products]);

  const searchOnType = useCallback(
    async (value) => {
      setSearchObject(value);

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        fetchProducts(1, value);
      }, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <>
      <style>{`@page { size: 5.00126cm 2.99974cm; margin: 0cm }`}</style>
      <LoadingComponent isLoading={loading} />
      <div className="row">
        <div className="col-12">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">พิมพ์สติกเกอร์สินค้า</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>ค้นหาสินค้า</label>
                        <div className="input-group md-form form-sm form-1 pl-0">
                          <div className="input-group-prepend">
                            <span className="input-group-text purple lighten-3">
                              <i
                                className="fas fa-search text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <input
                            value={searchObject.text}
                            onChange={(e) =>
                              searchOnType({
                                text: e.target.value,
                                products: productSelect,
                              })
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.key === "Backspace") {
                                searchOnType({
                                  text: e.target.value,
                                  products: productSelect,
                                });
                              }
                            }}
                            className="form-control my-0 py-1"
                            type="text"
                            placeholder={"ค้นหา"}
                            aria-label="Search"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr className="d-flex">
                              <th className="col-1">ลำดับ</th>
                              <th className="col-3">ชื่อสินค้า</th>
                              <th className="col-2">ตำแหน่ง</th>
                              <th className="text-center col-2">จำนวน</th>
                              <th className="text-center col-2">เลือก</th>
                              <th className="text-center col-2">
                                จำนวนที่เลือก
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {products.map((product, idx) => {
                              return (
                                <tr className="d-flex" key={product.id}>
                                  <td className="text-center col-1">
                                    {(pageNow - 1) * 10 + (idx + 1)}
                                  </td>
                                  <td className="col-3">{product.name}</td>
                                  <td className="col-2">
                                    {product.treasury
                                      ? product.treasury.place
                                      : "-"}
                                  </td>
                                  <td className="text-right col-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="จำนวน"
                                      onChange={(e) =>
                                        handleTotalChange(e, idx)
                                      }
                                      value={product.total ? product.total : ""}
                                    />
                                    {product.total && product.total_error && (
                                      <span className="text-danger">
                                        {product.total_error_message}
                                      </span>
                                    )}
                                  </td>
                                  <td className="text-center col-2">
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() => handleChooseTotal(idx)}
                                    >
                                      เลือก
                                    </button>
                                  </td>
                                  <td className="text-center col-2">
                                    {product.amount}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      <nav aria-label="Page navigation example">
                        <ReactPaginate
                          previousLabel={"ก่อนหน้า"}
                          forcePage={+pageNow - 1}
                          nextLabel={"ต่อไป"}
                          pageCount={productTotal}
                          pageRangeDisplayed={5}
                          previousClassName={"page-item"}
                          marginPagesDisplayed={1}
                          nextClassName={"page-item"}
                          pageClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextLinkClassName={"page-link"}
                          pageLinkClassName={"page-link"}
                          onPageChange={(e) => handlePageClick(e.selected)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5">
                  <div className="row">
                    {productSelect.map((barcode, idx) => {
                      return (
                        <div className="col-12" key={idx}>
                          <div className="barcode-label">
                            <Barcode renderer={"img"} value={barcode.value} />
                            <button
                              type="button"
                              className="close-barcode"
                              onClick={() =>
                                handleClickDeleteBarcode(barcode.productId, idx)
                              }
                            ></button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {productSelect.length > 0 && (
                    <div className="row mt-2 text-center">
                      <div className="col-12">
                        <button
                          type="button"
                          className="btn btn-danger"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="fas fa-trash-alt"></i> ลบทั้งหมด
                        </button>
                        <ReactToPrint
                          trigger={() => (
                            <button
                              type="submit"
                              className="btn btn-dark ml-md-3"
                            >
                              <i className="fas fa-print"></i> พิมพ์สติกเกอร์
                            </button>
                          )}
                          content={() => componentRef.current}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-none"> */}
        <ComponentToPrint ref={componentRef} label={productSelect} />
      {/* </div> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                ยืนยันการลบสินค้าที่เลือก
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              คุณต้องการลบสินค้าที่เลือกหรือไม่ ?
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleClickDeleteAllBarcode()}
                data-dismiss="modal"
              >
                ตกลง
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintLabel);
