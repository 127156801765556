export const initUserData = value => {
  return {
    type: "INIT_USER_DATA",
    value: value
  };
};

export const resetUserData = () => {
  return {
    type: "RESET_USER_DATA"
  };
};
