import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "../../config/service";
import moment from "moment";
import currency from "currency.js";
import { setTitleHeader } from "../../actions/menus";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import DatePicker, { registerLocale } from "react-datepicker";
import LoadingComponent from "../other/loading";
import { useAlert } from "react-alert";
import customHeader from "../calendar/CustomHeaderCalendar";

import "react-datepicker/dist/react-datepicker.css";

import { th } from "date-fns/esm/locale";
registerLocale("th", th);

const HistoryWarehouse = ({ setTitleHeader }) => {
  const alert = useAlert();
  const [warehouseHistory, setWarehouseHistory] = useState([]);
  const [products, setProducts] = useState([]);
  const [dateSelected, setDateSelected] = useState(null);
  const [warehouseTotal, setWarehouseTotal] = useState(0);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [dateValue, setDateValue] = useState(null);
  const [importDate, setImportDate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [pageNow, setPage] = useState(1)
  const isMounted = useRef(true);

  const fetchWarehouseHistory = useCallback(async (page = 1, date = null) => {
    setLoading(true);
    try {
      const warehouseAPI = await axios.get(
        `warehouse${date ? `?page=${page}&date=${date}` : `?page=${page}`}`
      );
      const {
        data: {
          warehouse: { last_page, data: warehouse },
        },
      } = warehouseAPI;
      setWarehouseTotal(last_page);
      setWarehouseHistory(warehouse);
      setLoading(false);
      setPage(page)
    } catch (error) {
      if (error.response && error.response.status === 500) {
        alert.error("มีข้อผิดพลาดเกิดขึ้น.");
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTitleHeader({
      title: "ประวัติเพิ่มสินค้าเข้าคลัง",
      href: "/warehouse/history",
    });

    fetchWarehouseHistory();
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTitleHeader]);

  const searchByDate = useCallback(
    (value) => {
      setDateValue(value);
      fetchWarehouseHistory(1, moment(value).format("YYYY-MM-DD"));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handlePageClickWarehouse = useCallback(
    (data) => {
      fetchWarehouseHistory(
        data.selected + 1,
        dateValue ? moment(dateValue).format("YYYY-MM-DD") : null
      );
    },
    [dateValue, fetchWarehouseHistory]
  );

  const handlePageClickHistory = useCallback(
    async (data) => {
      setLoading(true);
      try {
        const historyAPI = await axios.get(
          `warehouse/${moment(importDate).format(
            "YYYY-MM-DD"
          )}/import_date?page=${data.selected + 1}`
        );
        const {
          data: {
            history: { last_page, data: history },
          },
        } = historyAPI;
        setProducts(history);
        setHistoryTotal(last_page);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 500) {
          alert.error("มีข้อผิดพลาดเกิดขึ้น.");
        }
        setLoading(false);
      }
    },
    [alert, importDate]
  );

  return (
    <>
      <LoadingComponent isLoading={loading} />
      <div className="row">
        <div className="col-sm-12">
          {/* Search form */}
          <DatePicker
            name="import_date"
            placeholderText={dateValue ? moment(dateValue)
              .add(543, "years")
              .format("D MMMM YYYY ") : moment()
              .add(543, "years")
              .format("D MMMM YYYY ")}
            className="form-control w-100"
            locale="th"
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            onChange={(value) => searchByDate(value)}
            renderCustomHeader={(props) => customHeader({ ...props })}
          />
        </div>
      </div>
      <div className="table-responsive text-nowrap mt-3">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-center">
                <i className="fa fa-eye" aria-hidden="true"></i>
              </th>
              <th className="text-center">วันที่ทำรายการ</th>
              <th className="text-center">ยอดรวมจำนวนสินค้านำเข้าทั้งหมด</th>
              <th className="text-center">หน่วยละ</th>
              <th className="text-center">ยอดรวม</th>
            </tr>
          </thead>
          <tbody>
            {warehouseHistory.map((warehouse) => {
              return (
                <tr key={warehouse.id}>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-info"
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={() => {
                        setProducts(warehouse.history.data);
                        setHistoryTotal(warehouse.history.last_page);
                        setImportDate(warehouse.import_date);
                        setDateSelected(warehouse.import_date);
                      }}
                    >
                      <i className="fa fa-eye mr-1"></i>
                      ดูรายละเอียด
                    </button>
                  </td>
                  <td className="text-center">
                    {moment(warehouse.import_date).format("DD/MM/YYYY")}
                  </td>
                  <td className="text-right">{warehouse.amount_total}</td>
                  <td className="text-right">{
                    currency((+warehouse.cost_total * +warehouse.amount_total) / +warehouse.amount_total, 
                    {
                      separator: ",",
                      symbol: "",
                    }).format()
                  }</td>
                  <td className="text-right">{
                    currency(+warehouse.cost_total * +warehouse.amount_total, 
                    {
                      separator: ",",
                      symbol: "",
                    }).format()
                  }</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="row mt-3">
        <div className="col-12 d-flex justify-content-end">
          <nav aria-label="Page navigation example">
            <ReactPaginate
                    forcePage={+pageNow - 1}
              previousLabel={"ก่อนหน้า"}
              nextLabel={"ต่อไป"}
              pageCount={warehouseTotal}
              pageRangeDisplayed={5}
              previousClassName={"page-item"}
              marginPagesDisplayed={1}
              nextClassName={"page-item"}
              pageClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextLinkClassName={"page-link"}
              pageLinkClassName={"page-link"}
              onPageChange={handlePageClickWarehouse}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </nav>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModal"
        role="dialog"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                ประวัติเพิ่มสินค้าเข้าคลัง วันที่{" "}
                {dateSelected ? moment(dateSelected).add(543, 'years').format("DD/MM/YYYY") : "-"}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>ลำดับ</th>
                          <th>ผู้ทำรายงาน</th>
                          <th>รายการสินค้า</th>
                          <th>ประเภทนำเข้า</th>
                          <th className="text-right">จำนวน (ชิ้น)</th>
                          {
                            (products.length > 0 && products.every(p => p.cost !== null)) && (
                              <th className="text-right">ราคาต้นทุน (บาท)</th>
                            )
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, idx) => {
                          return (
                            <tr key={product.product_id + idx}>
                              <td>{idx + 1}</td>
                              <td className="text-center">
                                {product.created_by
                                  ? `${product.created_by.name} ${product.created_by.lastname}`
                                  : ``}
                              </td>
                              <td
                                className="td-word-break"
                                style={{ maxWidth: 100 }}
                              >
                                <p>
                                  {product.products
                                    ? product.products.name
                                    : "-"}
                                </p>
                              </td>
                              <td className="text-center">
                                {product.is_pack === "1" ? (
                                  <span className="badge badge-pill badge-primary">
                                    แพ็ค
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-secondary">
                                    ชิ้น
                                  </span>
                                )}
                              </td>
                              <td className="text-right">{product.total}</td>
                              {
                                product.cost !== null && <td className="text-right">{product.cost ? currency(product.cost, 
                                  {
                                    separator: ",",
                                    symbol: "",
                                  }).format() : '-'}</td>
                              }
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <ReactPaginate
                    previousLabel={"ก่อนหน้า"}
                    nextLabel={"ต่อไป"}
                    pageCount={historyTotal}
                    pageRangeDisplayed={5}
                    previousClassName={"page-item"}
                    marginPagesDisplayed={1}
                    nextClassName={"page-item"}
                    pageClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    pageLinkClassName={"page-link"}
                    onPageChange={handlePageClickHistory}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                ปิด
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setTitleHeader: (value) => {
    dispatch(setTitleHeader(value));
  },
});

export default connect(null, mapDispatchToProps)(HistoryWarehouse);
