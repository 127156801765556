const defaultState = {
  header: {
    title: "หน้าหลัก",
    href: "/"
  }
};

const MenuReducer = (state = defaultState, action) => {
  switch (action.type) {
    case "SET_TITLE_HEADER":
      return Object.assign({}, state, {
        header: action.value
      });
    default:
      return state;
  }
};

export default MenuReducer;
