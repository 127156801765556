import React, { Component } from "react";
import { withProfile } from "./utils/withProfile";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

const routes = [
  {
    href: "/users",
    title: "จัดการผู้ใช้ระบบ",
    iconClass: "fas fa-user",
    permission: "manage-users",
    submenu: [],
  },
  {
    href: "/customers",
    title: "จัดการข้อมูลลูกค้า",
    iconClass: "fas fa-male",
    permission: "manage-customers",
    submenu: [],
  },
  {
    href: "/products",
    title: "รายการสินค้าทั้งหมด",
    iconClass: "fas fa-box",
    permission: "manage-products",
    submenu: [],
  },
  {
    href: "/treasury",
    title: "ค้นหาตำแหน่งสินค้า",
    iconClass: "fas fa-location-arrow",
    permission: "manage-treasury",
    submenu: [],
  },
  {
    title: "จัดการสินค้าเข้าคลัง",
    permission: "manage-warehouse",
    href: "/warehouse",
    iconClass: "fas fa-archive",
    submenu: [
      {
        href: "/warehouse",
        title: "เพิ่มสินค้าเข้าคลัง",
        iconClass: "fas fa-plus-square",
      },
      {
        href: "/warehouse/history",
        title: "ประวัติ",
        iconClass: "fas fa-history",
      },
    ],
  },
  {
    href: "/pricing",
    title: "กำหนดราคาขาย",
    iconClass: "fas fa-wallet",
    permission: "manage-price",
    submenu: [],
  },
  {
    href: "/printlabel",
    title: "พิมพ์สติกเกอร์สินค้า",
    iconClass: "fas fa-print",
    permission: "manage-sticker",
    submenu: [],
  },
  {
    href: "/deal",
    title: "ขายหน้าร้าน",
    iconClass: "fas fa-cash-register",
    permission: "manage-seller",
    submenu: [],
  },
  {
    href: "/boughtback",
    title: "ซื้อของคืนเข้าระบบ",
    iconClass: "fas fa-undo",
    permission: "manage-refund",
    submenu: [],
  },
  {
    href: "/refund-stock",
    title: "จัดการแตกสินค้า",
    iconClass: "fas fa-hand-spock",
    permission: "manage-stock-split",
    submenu: [],
  },
  {
    href: "/expense",
    title: "รายจ่ายอื่นๆ",
    iconClass: "fas fa-receipt",
    permission: "manage-expense",
    submenu: [],
  },
];

class Menu extends Component {
  render() {
    const {
      user: { roles, permissions },
    } = this.props;
    const setRoute = routes.filter((route) => {
      if (roles.length > 0) {
        if (roles[0].name !== "Super Admin") {
          if (permissions.length > 0 && route.permission) {
            const hasPermission = permissions.filter(
              (permission) => permission.name === route.permission
            );
            if (hasPermission.length) {
              return route;
            }
            return;
          }
          return;
        }
      }
      return route;
    });
    return (
      <aside
        id="sidebar"
        className="main-sidebar sidebar-dark-primary elevation-4"
      >
        {/* Brand Logo */}
        <Link to="/" className="brand-link text-center">
          <span className="brand-text font-weight-light">POS-STOCK</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex text-center">
            <div className="info w-100">
              <Link to="">สวัสดีค่ะ {this.props.owner}</Link>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
              ref="treeviewRef"
            >
              {/* Add icons to the links using the .nav-icon class
        with font-awesome or any other icon font library */}
              {setRoute.map((route, idx) => {
                if (route.submenu.length > 0) {
                  return (
                    <li
                      key={idx + route.title}
                      className={`nav-item has-treeview${
                        this.props.location.pathname.includes(route.href)
                          ? " menu-open"
                          : ""
                      }`}
                    >
                      <a
                        href="#"
                        className={`nav-link nav-link-preview ${
                          this.props.location.pathname.includes(route.href)
                            ? "active"
                            : ""
                        }`}
                        key={idx}
                        onClick={(e) => e.preventDefault()}
                      >
                        <i
                          className={`nav-icon nav-link-preview ${route.iconClass}`}
                        ></i>
                        <p className="nav-link-preview">
                          {route.title}
                          <i
                            className={`fas fa-angle-left right nav-link-preview`}
                          ></i>
                        </p>
                      </a>
                      <ul
                        className="nav nav-treeview"
                        style={{
                          display: this.props.location.pathname.includes(
                            route.href
                          )
                            ? "block"
                            : "none",
                        }}
                      >
                        {route.submenu.map((submenu, idx) => {
                          return (
                            <li className="nav-item" key={idx + "submenu"}>
                              <NavLink
                                activeClassName="active"
                                isActive={(match, location) => {
                                  return (
                                    this.props.location.pathname ===
                                    submenu.href
                                  );
                                }}
                                to={submenu.href}
                                className="nav-link"
                              >
                                <i
                                  className={`nav-icon ${submenu.iconClass}`}
                                />
                                <p>{submenu.title}</p>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                } else {
                  return (
                    <li className="nav-item" key={idx}>
                      <NavLink
                        activeClassName="active"
                        to={route.href}
                        className="nav-link"
                      >
                        <i className={`nav-icon ${route.iconClass}`} />
                        <p>{route.title}</p>
                      </NavLink>
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    );
  }
}

const mapStateToProps = (state) => ({
  owner: `${state.user.name} ${state.user.lastname}`,
  user: state.user,
});

export default connect(mapStateToProps, null)(withProfile(Menu));
