import React, { Component } from "react";
import ReactImageMagnify from "react-image-magnify";
import ReactSlick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Carousel extends Component {
  render() {
    const { images } = this.props;
    return (
      <ReactSlick
        {...{
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: false,
        }}
      >
        {images ? (
          images.length ? (
            images.map((image) => {
              return (
                <ReactImageMagnify
                  key={image.id}
                  {...{
                    smallImage: {
                      alt: image.id,
                      isFluidWidth: true,
                      src: image.path.small,
                    },
                    largeImage: {
                      src: image.path.large,
                      width: 1200,
                      height: 1800,
                    },
                    enlargedImagePosition: "over",
                  }}
                />
              );
            })
          ) : (
            <ReactImageMagnify
              {...{
                smallImage: {
                  alt: "Wristwatch by Ted Baker London",
                  isFluidWidth: true,
                  src: "/images/image_default.jpg",
                },
                largeImage: {
                  src: "/images/image_default.jpg",
                  width: 1200,
                  height: 1800,
                },
                enlargedImagePosition: "over",
              }}
            />
          )
        ) : (
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: "Wristwatch by Ted Baker London",
                isFluidWidth: true,
                src: "/images/image_default.jpg",
              },
              largeImage: {
                src: "/images/image_default.jpg",
                width: 1200,
                height: 1800,
              },
              enlargedImagePosition: "over",
            }}
          />
        )}
      </ReactSlick>
    );
  }
}
