import React, { Component } from "react";
import axios from "./config/service";
import { withRouter, Link } from "react-router-dom";
import { resetUserData } from "./actions/users";
import { connect } from "react-redux";

class Header extends Component {
  logout = async () => {
    try {
      await axios.post(`auth/logout`);
      this.logoutLogic();
    } catch (error) {
      if (error.response && error.response.status === 500) {
        this.logoutLogic();
      }
    }
  };

  logoutLogic = () => {
    localStorage.removeItem("token");
    this.props.resetUserData();
    this.props.history.push("/login");
  };

  render() {
    const { header } = this.props;
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a id="pushmenu" className="nav-link" data-widget="pushmenu">
              <i className="fas fa-bars" />
            </a>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to={`${header.href}`} className="nav-link">
              {header.title}
            </Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          {/* Notifications Dropdown Menu */}
          {/* <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <i className="far fa-bell" />
              <span className="badge badge-warning navbar-badge">15</span>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">
                15 Notifications
              </span>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-envelope mr-2" /> 4 new messages
                <span className="float-right text-muted text-sm">3 mins</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-users mr-2" /> 8 friend requests
                <span className="float-right text-muted text-sm">12 hours</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item">
                <i className="fas fa-file mr-2" /> 3 new reports
                <span className="float-right text-muted text-sm">2 days</span>
              </a>
              <div className="dropdown-divider" />
              <a href="#" className="dropdown-item dropdown-footer">
                See All Notifications
              </a>
            </div>
          </li> */}
          <li className="nav-item" onClick={this.logout}>
            <span className="nav-link">
              <i className="fas fa-sign-out-alt" />
            </span>
          </li>
        </ul>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  header: state.menu.header
});

const mapDispatchToProps = dispatch => ({
  resetUserData: () => {
    dispatch(resetUserData());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
