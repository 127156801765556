import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import Authorization from "./Authorization";

const UserRoute = ({ permissionAccess, component: Component, ...rest }) => (
  <Route
    exact
    {...rest}
    render={props =>
      localStorage.getItem("token") ? (
        <Authorization permissionAccess={permissionAccess}>
          <Component {...props} />
        </Authorization>
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

UserRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default UserRoute;
