import React from "react";

const Button = ({isLoading, children, ...rest}) => {
  return (
    <button {...rest}>
      {isLoading && (
        <span
          class="spinner-border spinner-border-sm mr-2"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {children}
    </button>
  );
};

export default Button;
